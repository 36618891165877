import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Box,
  DialogContent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { toast, ToastContainer } from "react-toastify";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import EditBrandPopup from "../PopupScreens/EditPopupscreeen/EditBrandPopup";
import { useNavigate } from "react-router-dom";

export default function BranchTable({ refresh, setrefresh }) {
  const AppCode = global.$userData.logindata.AppCode;
  const Navigate = useNavigate();

  const userId = global.$userData.logindata.UserId;
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  //edit
  const [deletedata, setdeletedata] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Multipledelete, setMultipledelete] = useState(false);
  const handleClosemultiple = () => setMultipledelete(false);

  const active = async () => {
    const response = await global.$axios
      .get(
        // /userinfo/branch/activelist/:AppCode
        `userinfo/branch/activelist/${AppCode}`
      )
      .then(async (response) => {
        setBranchRow([...response.data.Message]);
        setBranchSearch([...response.data.Message]);
      })
      .catch((e) => {
        console.error("Brandactive", e);
      });
  };

  useEffect(() => {
    active();
  }, [refresh]);

  const userdelete = async (id) => {
    const deleteResponse = await global.$axios
      .put(
        // /userinfo/branch/delete/:AppCode/:id
        `userinfo/branch/delete`,
        {
          AppCode: AppCode,
          BranchId: id,
        }
      )
      .then((deleteResponse) => {
        if (deleteResponse.data.status === 200) {
          active();
          toast.success("Moved To Inactive");
        } else if (deleteResponse.data.status === 201) {
          toast.error(`${deleteResponse.data.Message}`);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("branchdelete", e);
      });
  };

  //search

  const [BranchRow, setBranchRow] = useState([]);
  const [BranchSearch, setBranchSearch] = useState([]);

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = BranchRow.filter((item) => {
        const BranchId = String(item?.BranchId);
        const BranchName = String(item?.BranchName);
        const BranchCode = String(item?.BranchCode);
        const Cities = String(item?.Cities?.CityName);
        const Countries = String(item?.Countries?.CountryName);
        const States = String(item?.States?.StateName);

        const text_data = term.toLowerCase();
        return (
          BranchId.indexOf(text_data) > -1 ||
          BranchName.toLowerCase().indexOf(text_data) > -1 ||
          BranchCode.toLowerCase().indexOf(text_data) > -1 ||
          Cities.toLowerCase().indexOf(text_data) > -1 ||
          States.toLowerCase().indexOf(text_data) > -1 ||
          Countries.toLowerCase().indexOf(text_data) > -1
        );
      });

      setBranchSearch(ticketData);
    } else {
      setBranchSearch(BranchRow);
    }
  }
  //Default Sorting
  const [sortModel, setSortModel] = useState([
    {
      field: "BranchId",
      sort: "desc",
    },
  ]);
  const columns = [
    {
      field: "BranchId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Edit",
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        // console.log(cellValues, "cellValues");
        return (
          <>
            <Tooltip title="Edit">
              <EditIcon
                onClick={() => {
                  Navigate("EditBranch", {
                    state: { data: cellValues.row },
                  });
                }}
                style={{
                  width: "23px",
                  height: "23px",
                  cursor: "pointer",
                  padding: "2px",
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon
                // onClick={() => userdelete(cellValues.row)}
                onClick={() => {
                  setdeletedata(cellValues.row);
                  handleOpen();
                }}
                style={style1.img}
              />
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "BranchName",
      headerClassName: "super-app-theme--header",
      headerName: "Branch",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "BranchCode",
      headerClassName: "super-app-theme--header",
      headerName: "Branch Code",
      width: 150,
    },
    {
      field: "Countries",
      headerClassName: "super-app-theme--header",
      headerName: "Countries",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (fields) => fields.row?.Countries?.CountryName || "",
    },
    {
      field: "States",
      headerClassName: "super-app-theme--header",
      headerName: "States",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (fields) => fields.row?.States?.StateName || "",
    },
    {
      field: "Cities",
      headerClassName: "super-app-theme--header",
      headerName: "Cities",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (fields) => fields.row?.Cities?.CityName || "",
    },
  ];

  return (
    <>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        md={3}
        sm={3}
        xs={12}
      >
        <Box
          id="searcBox"
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            float: "right",
            margin: "10px 0px 10px 0px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={styles.searchInput}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <SearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <div style={{ height: 480, width: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...BranchRow.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={BranchSearch}
          columns={columns}
          rowHeight={38}
          pageSize={10}
          getRowId={(row) => row.BranchId}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          components={{
            Footer: () => (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0px 5px 0px 10px",
                  }}
                >
                  <Grid container item xs={12} style={{ alignItems: "center" }}>
                    {rowSelectionModel.length >= 1 ? (
                      <Grid item xs={2}>
                        <DeleteIcon
                          onClick={() => setMultipledelete(true)}
                          style={{ cursor: "pointer" }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}></Grid>
                    )}
                    <Grid item xs={10}>
                      <GridPagination />
                    </Grid>
                  </Grid>
                </div>
              </>
            ),
          }}
        />
        {open && (
          <Dialog
            fullWidth
            maxWidth={"xs"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                fontWeight: "400",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  margin: "0px",
                }}
              >
                Do You Want To Delete
              </p>{" "}
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: 600,
                  margin: "0px",
                  color: "#0071BC",
                }}
              >
                {deletedata.BranchName}
              </p>
            </DialogTitle>

            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={() => {
                  userdelete(deletedata.BranchId);
                  handleClose();
                }}
                autoFocus
              >
                Yes
              </Button>
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={handleClose}
              >
                No{" "}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {Multipledelete && (
          <Dialog
            fullWidth
            maxWidth={"xs"}
            open={Multipledelete}
            onClose={handleClosemultiple}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                fontWeight: "400",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                sx={{ borderBottom: 1 }}
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    margin: "0px",
                    color: "rgb(45,113,188)",
                  }}
                >
                  Delete confirm
                </span>{" "}
              </Grid>
            </DialogTitle>
            <DialogContent sx={{ px: 3 }}>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  margin: "0px",
                }}
              >
                Do You Want To Delete{" "}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "rgb(45,113,188)",
                  }}
                >
                  {rowSelectionModel.length}{" "}
                </span>{" "}
                Branch ?
              </span>{" "}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "10px 15px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={() => {
                  userdelete(rowSelectionModel);
                  handleClosemultiple();
                }}
                autoFocus
              >
                Yes
              </Button>
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={handleClosemultiple}
              >
                No{" "}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
        {/* {EditPopupOpen && (
                <EditBrandPopup
                    active={active}
                    data={Edit}
                    EditPopupOpen={EditPopupOpen}
                    setEditPopupOpen={setEditPopupOpen}
                />
            )} */}
      </div>
    </>
  );
}
const style1 = {
  img: {
    width: "23px",
    height: "23px",
    cursor: "pointer",
    padding: "2px",
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};
