import * as React from "react";
import Paper from "@mui/material/Paper";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import moment from "moment";
import { Box, Grid, Tooltip } from "@mui/material";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { ViewIcon } from "../../Assets/Icon";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import TicketTransfer from "./TicketTransfer";
import { GridSearchIcon } from "@mui/x-data-grid";
import dayjs from "dayjs";
export default function THoldTable({ Id, DepartmentValue }) {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [refresh, Setrefresh] = useState(false);
  //table data
  const [Hold, setHold] = useState([]);
  const [search, setsearch] = useState([]);
  const LocalSearchvalue = localStorage.getItem("THoldTblSrch");
  const [SearchValue, setSearchValue] = useState(LocalSearchvalue);

  const currentTime = new Date();
  const Navigate = useNavigate();

  //popup
  const [Transferopen, SetTransferopen] = useState(false);
  const [Edit, SetEdit] = useState("");

  const HoldList = async () => {
    const data = {
      AppCode: AppCode,
      DepartmentId: [DepartmentValue.DepartmentId],
      UserId: Id,
    };
    // /ticket/team/holdingtickets
    const response = await global.$axios
      .post(`ticket/team/holdingtickets`, data)
      .then(async (response) => {
        if (response.data.Status == 200) {
          setHold(response.data.Message);
          let ticketData = [];
          if (LocalSearchvalue) {
            ticketData = response.data.Message.filter((item) => {
              const TicketId = String(item?.TicketId);
              const CompanyName = String(item?.contact?.account?.CompanyName);
              const ContactName = String(item?.contact?.ContactName);
              const ProductName = String(item?.product?.ProductName);
              const IssueName = String(item?.issue?.IssueName);
              const UpdatedBy = String(item?.updated?.UserFirstName);
              const ETC = String(
                moment(item?.ECAt, "YYYY-MM-DD hh:mm").format(
                  "DD-MM-YYYY hh:mm A"
                )
              );
              const ClosedTime = String(
                moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
                  "DD-MM-YYYY hh:mm A"
                )
              );
              // const Description = String(item?.TicketDescription);
              const AssignedBy = String(item?.created?.UserFirstName);
              const Status = String(item?.Status);

              const text_data = LocalSearchvalue.toLowerCase();
              return (
                TicketId.indexOf(text_data) > -1 ||
                CompanyName.toLowerCase().indexOf(text_data) > -1 ||
                ContactName.toLowerCase().indexOf(text_data) > -1 ||
                ProductName.toLowerCase().indexOf(text_data) > -1 ||
                IssueName.toLowerCase().indexOf(text_data) > -1 ||
                UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
                ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
                AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
                ETC.toLowerCase().indexOf(text_data) > -1 ||
                Status.toLowerCase().indexOf(text_data) > -1
              );
            });
            setsearch(ticketData);
          } else {
            setsearch(response.data.Message);
          }
        } else {
          setHold([]);
          setsearch([]);
        }
      })
      .catch((e) => {
        console.error("Team Hold List Issue", e);
      });
  };

  useEffect(() => {
    if (Id.length != 0) {
      HoldList();
    } else {
      setHold([]);
    }
  }, [Id, refresh]);

  //Mui Premium

  //Default Sorting
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "asc" }],
      },
    },
  });

  const columns = [
    {
      field: "TicketId",
      headerName: "Ticket ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
      cellClassName: (params) => {
        let startDate = dayjs();
        var date = `${startDate.$d.getFullYear()}-${
          startDate.$d.getMonth() + 1 < 10
            ? "0" + (startDate.$d.getMonth() + 1)
            : startDate.$d.getMonth() + 1
        }-${
          startDate.$d.getDate() < 10
            ? "0" + startDate.$d.getDate()
            : startDate.$d.getDate()
        }`;
        var date3 = moment(params.row.ECAt, "YYYY-MM-DD hh:mm A").format(
          "YYYY-MM-DD"
        );
        var date4 = moment(
          params.row.TicketClosedTime,
          "YYYY-MM-DD hh:mm A"
        ).format("YYYY-MM-DD");
        return clsx("super-app", {
          Sla: date3 < date && date4 > date3,
        });
      },
    },
    {
      field: "AttachmentFile" || "",
      headerName: "Actions",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="Ticket Transfer">
              <CompareArrowsIcon
                style={{ width: "23px", height: "20px" }}
                onClick={() => {
                  SetTransferopen(true);
                  SetEdit(cellValues.row);
                }}
              />
            </Tooltip>

            <Tooltip title="View Ticket">
              <ViewIcon
                style={{ width: "20px", height: "20px" }}
                onClick={() =>
                  Navigate("TeamView", {
                    state: { data: cellValues.row, Table: search },
                  })
                }
              />
            </Tooltip>
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.account?.CompanyName,
    },
    {
      field: "ContactName",
      headerName: "Contact Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
    },

    {
      field: "Updated By",
      headerName: "Updated By ",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: UpdatedFullName,
    },
    {
      field: "Updated At",
      headerName: "Updated At",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YY hh.mm A"
        ) || "",
    },
    {
      field: "ECAt",
      headerName: " Time To Complete",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.ECAt).format("DD-MM-YY hh.mm A") || "",
    },
    {
      field: "TicketDescription",
      headerName: "Description",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.TicketDescription || "",
    },
  ];

  function UpdatedFullName(fields) {
    return `${fields?.row?.updated?.UserFirstName || ""} ${
      fields?.row?.updated?.UserLastName || ""
    }`;
  }

  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = Hold.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const ETC = String(
          moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
        );
        const ClosedTime = String(
          moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        // const Description = String(item?.TicketDescription);
        const AssignedBy = String(item?.created?.UserFirstName);
        const Status = String(item?.Status);

        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
          // Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1 ||
          Status.toLowerCase().indexOf(text_data) > -1
        );
      });
      setsearch(ticketData);
    } else {
      setsearch(Hold);
    }
  }
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "5px",
        }}
      >
        <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
          Hold
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            List
          </span>
        </p>
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            value={SearchValue}
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
            }}
            autoComplete="off"
            onChange={(e) => {
              filterList(e.target.value);
              setSearchValue(e.target.value);
              setTimeout(() => {
                localStorage.setItem("THoldTblSrch", e.target.value);
              }, 2000);
            }}
          />

          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <Paper sx={{ width: "100%" }}>
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
              "& .super-app.Sla": {
                backgroundColor: "rgb(252,204,203)",
              },
            }}
            rowHeight={38}
            {...data}
            apiRef={apiRef}
            columns={columns}
            rows={search ? search : []}
            getRowId={(row) => row.TicketId}
            loading={loading}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            pagination={true}
            disableRowSelectionOnClick
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        </Box>
        {Transferopen && (
          <TicketTransfer
            data={Edit}
            Transferopen={Transferopen}
            SetTransferopen={SetTransferopen}
            Setrefresh={Setrefresh}
            refresh={refresh}
          />
        )}
      </Paper>
    </>
  );
}
