import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ProfileEdit from "../Components/ProfileEdit";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { Close } from "@mui/icons-material";

const Profile = () => {
  const dispatch = useDispatch();
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const config = global.$userData.logindata.crm_app_license_master;
  const [openProfile, setOpenProfile] = useState(false);
  const [AttachmentLink, setAttachmentLink] = useState("");
  const [configdailog, setconfigdailog] = useState(false);
  const [checked, setChecked] = useState(
    config.internal_ticket == "Yes" ? true : false
  );
  const [request, setrequest] = useState(false);
  const [company, setCompany] = useState(null);
  const [companyDrop, setCompanyDrop] = useState([]);
  const [contact, setContact] = useState(null);
  const [contactDrop, setContactDrop] = useState([]);
  const handleChange = () => {
    if (!checked) {
      setrequest(true);
      companyList();
    } else {
      configapi(true);
    }
    setChecked(!checked);
  };
  const configapi = async (value) => {
    console.log("value", value);

    if ((!value && contact == null) || (!value && company == null)) {
      console.log("value2", value);
      toast.error("Please Fill The Required Field");
      return;
    }
    try {
      const { data } = await global.$axios.post(`/auth/internalConfig`, {
        AppCode: AppCode,
        UserId: userId,
        Config: !value ? "Yes" : "No",
        Intcompany: !value ? company.AccountsId : "",
        Intcontact: !value ? contact.ContactId : "",
      });
      console.log("data", data);
      if (data.Status === 200) {
        toast.success("Config Updated Successfully");
        setrequest(false);
        setCompany(null);
        setContact(null);
        dispatch({
          type: "SET_USER_IN",
          logindata: data.Message,
          payload: true,
        });
        if (value) {
          setconfigdailog(false);
        }
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("configupdate", error);
    }
  };
  const handleFileChange = (e) => {
    console.log("value", typeof document.getElementById("actual-btn").value);
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadClick(chosenFiles);
  };
  const handleClose = () => {
    setconfigdailog(!configdailog);
  };
  const handleClose1 = () => {
    setconfigdailog(true);
    setChecked(config.internal_ticket == "Yes" ? true : false);
    setrequest(!request);
  };
  const handleUploadClick = async (file) => {
    console.log("file", file);
    {
      file.forEach(async (files, index) => {
        var formData = new FormData();
        formData.append("file", files);
        const response = await global.$axios
          .post("ticket/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then(async (response) => {
            console.log("data", response.data);
            if (response.data.Status === 200) {
              setAttachmentLink(response.data.Message);
              submitUpload(response.data.Message);
            } else {
              toast.error("Something Went Wrong");
            }
          })

          .catch((e) => {
            console.error("Upload API Issue", e);
          });
      });
    }
  };

  const submitUpload = async (link) => {
    try {
      const payload = {
        AppCode: AppCode,
        UserId: userId,
        ProfileImageLink: link,
      };
      const { data } = await global.$axios.post(
        "userinfo/uploadImage",
        payload
      );
      if (data.Status === 200) {
        toast.success("Profile Uploded Successfully");
        dispatch({
          type: "SET_USER_IN",
          logindata: data.Message,
          payload: true,
        });
      }
      console.log("submitUpload", data);
    } catch (error) {
      console.log("submitUpload Issue", error);
    }
  };
  const companyList = async () => {
    try {
      const response = await global.$axios
        .get(`/ticket/companies/${AppCode}`)
        .then(async (response) => {
          setCompanyDrop(response.data.Message);
        })
        .catch((e) => {
          console.log("Company List Issue", e);
        });
    } catch (error) {
      console.error("compaylist", error);
    }
  };
  const contactList = async (AccountId) => {
    try {
      if (!AccountId) {
        setContactDrop([]);
        setContact(null);
      } else {
        const response = await global.$axios
          .get(`/ticket/contacts/${AppCode}/${AccountId}`)
          .then(async (response) => {
            setContactDrop(response.data.Message);
            setContact(response.data.Message[0]);
          })
          .catch((e) => {
            console.log("Contact List Issue", e);
          });
      }
    } catch (error) {
      console.error("contactList", error);
    }
  };

  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          item
          md={4}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: "3px solid rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "10px",
            lineHeight: "30px",
            background: "#FFF",
            boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
          }}
        >
          {/* {(global?.$userData?.logindata?.TicketCompaniesAccess == "1" ||
            global?.$userData?.logindata?.TicketCompaniesAccess == "2") && (
            <Grid
              container
              item
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Tooltip title="Config">
                <IconButton onClick={() => setconfigdailog(true)}>
                  <SettingsIcon
                    sx={{
                      color: "rgb(45,113,188)",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )} */}
          <Link
            target="blank"
            to={global?.$userData?.logindata?.ProfilePictureLink || ""}
          >
            <Avatar
              alt={global?.$userData?.logindata?.UserName}
              src={global?.$userData?.logindata?.ProfilePictureLink || ""}
              sx={{ width: 100, height: 100 }}
            />
          </Link>
          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              gap: "10px",
            }}
          >
            <span style={{ margin: "0px" }}>
              {global.$userData.logindata.UserName}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "80%",
            }}
          >
            <Button
              size="small"
              sx={{
                margin: " 0px",
                textTransform: "capitalize",
                fontSize: "12px",
              }}
              onClick={() => setOpenProfile(true)}
            >
              Edit Profile
            </Button>

            <div style={{ display: "none" }}>
              <input
                type="file"
                accept="text"
                id="actual-btn"
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </div>
            <label
              for="actual-btn"
              style={{
                color: "#0071BC",
                fontSize: "12px",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              Choose Profile Picture
            </label>
          </div>
        </Grid>
      </Grid>
      {configdailog && (
        <Dialog
          open={configdailog}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"xs"}
          keepMounted
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px 10px",
            }}
          >
            <Typography variant="subtitle2" sx={{ color: "rgb(45,113,188)" }}>
              Configs
            </Typography>
            <IconButton onClick={() => handleClose()}>
              <Close sx={{ color: "rgb(45,113,188)" }} />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1">Internal Ticket </Typography>
            <Checkbox checked={checked} onChange={handleChange} />
          </DialogContent>
        </Dialog>
      )}
      {request && (
        <Dialog
          open={request}
          onClose={handleClose1}
          fullWidth={true}
          maxWidth={"xs"}
          keepMounted
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px 10px",
            }}
          >
            <Typography variant="subtitle2" sx={{ color: "rgb(45,113,188)" }}>
              Internal Ticket
            </Typography>
            <IconButton onClick={() => handleClose1()}>
              <Close sx={{ color: "rgb(45,113,188)" }} />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "5px" }}>
                <span style={{ fontSize: "14px", fontWeight: 500 }}>
                  Company Name
                </span>
                <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>

                <Autocomplete
                  id="auto-complete"
                  size="small"
                  fullWidth
                  options={companyDrop}
                  value={company}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      contactList(null);
                    } else {
                      contactList(newValue.AccountsId);
                    }
                    setCompany(newValue);
                  }}
                  getOptionLabel={(option) => option.CompanyName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.AccountsId}>
                      {option.CompanyName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Company Name"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "5px" }}>
                <span style={{ fontSize: "14px", fontWeight: 500 }}>
                  Contact Name
                </span>
                <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>

                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  fullWidth
                  value={contact}
                  options={contactDrop}
                  getOptionLabel={(option) => option.contactname}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.ContactId}>
                      {option.contactname}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Contact Name"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(event, newValue) => {
                    setContact(newValue);
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5px",
                }}
                xs={12}
              >
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize", px: 3 }}
                  onClick={() => configapi(false)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {openProfile && (
        <ProfileEdit
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default Profile;
