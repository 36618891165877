import { Box, Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const MailTicketIndex = () => {
  const Navigate = useNavigate();
  const getMailSearch = localStorage.getItem("MailSearch");

  const [mailRow, setMailRow] = useState([]);
  const [mailSearch, setMailSearch] = useState([]);
  const [searchText, setSearchText] = useState("");

  //Default Sorting
  const [sortModel, setSortModel] = useState([
    {
      field: "UserId",
      sort: "desc",
    },
  ]);

  //Functions
  async function filterList(data, term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = data.filter((item) => {
        const UserId = String(item?.UserId);
        const UserName = String(item?.UserName);

        const text_data = term.toLowerCase();
        return (
          UserId.indexOf(text_data) > -1 ||
          UserName.toLowerCase().indexOf(text_data) > -1
        );
      });

      setMailSearch(ticketData);
    } else {
      setMailSearch(data);
    }
  }

  const columns = [
    {
      field: "EmailticketId",
      headerClassName: "super-app-theme--header",
      headerName: "Ticket ID",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <Button
            size="small"
            variant="contained"
            sx={{
              background: "#0071BC",
              ":hover": { background: "#0071BC" },
              mx: 2,
              textTransform: "capitalize",
            }}
            onClick={() =>
              Navigate("/app/create_mail_to_ticket", {
                state: { data: cellValues?.row },
              })
            }
          >
            Convert
          </Button>
        );
      },
    },

    {
      field: "For",
      headerClassName: "super-app-theme--header",
      headerName: "From",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return <div>{cellValues?.row?.For == "1" ? "User" : "Contact"}</div>;
      },
    },

    {
      field: "Fromaddress",
      headerClassName: "super-app-theme--header",
      headerName: "Mail",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "Summary",
      headerName: "Summary",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      //   renderCell: (cellValues) => {
      //     return (
      //       <div
      //         style={{ padding: "5px", fontSize: "14px" }}
      //         dangerouslySetInnerHTML={{
      //           __html: cellValues?.row?.Htmltag,
      //         }}
      //       />
      //     );
      //   },
    },
  ];

  //APIs

  const getMailList = async () => {
    try {
      const { data } = await global.$axios.get("ticket/getemailtickets");
      if (data.Status == 200) {
        setMailRow(data.Message);
        setMailSearch(data.Message);
        filterList(data.Message, getMailSearch || "");
      } else {
        console.log("get Mail List Issue", data.Message);
      }
    } catch (error) {
      console.log("get Mail List Issue", error);
    }
  };

  useEffect(() => {
    getMailList();
  }, []);

  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        position: "relative",
      }}
    >
      <p
        style={{
          margin: "0px 0px 0px 0px",
          color: "#0071BC",
          fontSize: "18px",
          fontWeight: "600",
          cursor: "pointer",
        }}
      >
        Mail To Ticket
      </p>

      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: 40,
        }}
        md={3}
        sm={3}
        xs={12}
      >
        <Box
          id="searcBox"
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            float: "right",
            margin: "10px 0px 10px 0px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={styles.searchInput}
            autoComplete="off"
            value={searchText}
            onChange={(e) => {
              localStorage.setItem("MailSearch", e.target.value);
              filterList(mailRow, e.target.value);
              setSearchText(e.target.value);
            }}
          />
          <SearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <div style={{ height: "480", width: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            width: "100%",
            height: "70vh",
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...mailRow.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={mailSearch}
          getRowId={(row) => row.EmailticketId}
          rowHeight={38}
          columns={columns}
          pageSize={10}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          //   checkboxSelection
        />
      </div>
    </Grid>
  );
};

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};

export default MailTicketIndex;
