import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Branchtables from "./Customer screens/BranchTables";
import Contacttable from "./Customer screens/Contacttable";
import CompanyTable from "./Customer screens/Companytable";
import Addmorcontact from "./Customer screens/Addmorcontact";
import { Box } from "@material-ui/core";
import { useEffect } from "react";
import Companyinactive from "./Customer screens/CompanyInactive";
import BranchInactive from "./Customer screens/BranchInactive";
import ContactInactive from "./Customer screens/ContactInactive";

export default function Customer() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  var Tabactive = localStorage.getItem("CustomerActive");
  const [CActive, setCActive] = useState(
    Tabactive !== null ? Tabactive : "Active"
  );
  var swift = localStorage.getItem("CompanyTab");
  const [tab, settab] = useState(swift !== null ? swift : "Company");
  var Drop = localStorage.getItem("DropValue");

  const [ContactOwnerDrop, setContactOwnerDrop] = useState([
    { ContactOwner: "All", UserId: "All" },
  ]);
  const [AllOwnerDrop, setAllOwnerDrop] = useState([]);
  const [OwnerId, setOwnerId] = useState([]);
  const [ContactOwner, setContactOwner] = useState(
    global?.$userData?.logindata?.TicketCompaniesAccess == "2"
      ? null
      : Drop !== null
      ? JSON.parse(Drop)
      : { ContactOwner: "All", UserId: "All" }
  );

  //Popup
  const [ContactAddOpen, setContactAddOpen] = React.useState(false);
  const [Refresh, setRefresh] = useState(false);
  //API
  const contactOwnerList = async () => {
    const response = await global.$axios
      .get(`/company/getuserlist/${AppCode}/${userId}`)
      .then(async (response) => {
        {
          global?.$userData?.logindata?.TicketCompaniesAccess == "2"
            ? setContactOwnerDrop(response.data.message)
            : setContactOwnerDrop([
                ...ContactOwnerDrop,
                ...response.data.message,
              ]);
        }
        {
          global?.$userData?.logindata?.TicketCompaniesAccess == "2" &&
            setContactOwner(response.data.message[0]);
        }
        setAllOwnerDrop(response.data.message);
        setOwnerId(
          Drop !== null && JSON.parse(Drop)?.UserId == "All"
            ? response.data.message.map((i) => i.UserId)
            : Drop !== null
            ? [JSON.parse(Drop)?.UserId]
            : response.data.message.map((i) => i.UserId)
        );
      })
      .catch((e) => {
        console.log("Contact Owner List", e);
      });
  };

  const Navigate = useNavigate();

  useEffect(() => {
    contactOwnerList();
  }, []);

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px 0px 5px 0px",
          }}
        >
          <p
            style={{
              margin: "0px 0px 0px 0px",
              color: "#0071BC",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Companies
          </p>
          <div style={{ display: "flex", gap: "15px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              variant={CActive === "Active" ? "contained" : "text"}
              onClick={() => {
                setCActive("Active");
                localStorage.setItem("CustomerActive", "Active");
              }}
            >
              Active
            </Button>
            <Button
              sx={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              variant={CActive === "Inactive" ? "contained" : "text"}
              onClick={() => {
                setCActive("Inactive");
                localStorage.setItem("CustomerActive", "Inactive");
              }}
            >
              Inactive
            </Button>
          </div>
          {/* )} */}
        </Grid>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px 0px 5px 0px",
          }}
        >
          <Grid
            item
            lg={3}
            sm={5}
            md={4}
            xs={12}
            style={{ marginBottom: "5px" }}
          >
            <Autocomplete
              size="small"
              fullWidth
              disableClearable
              id="auto-complete"
              options={ContactOwnerDrop}
              value={ContactOwner}
              onChange={(event, newValue) => {
                localStorage.setItem("DropValue", JSON.stringify(newValue));
                setContactOwner(newValue);
                if (newValue.UserId == "All") {
                  setOwnerId(AllOwnerDrop.map((i) => i.UserId));
                } else {
                  setOwnerId([newValue.UserId]);
                }
              }}
              getOptionLabel={(option) => option.ContactOwner}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.UserId}>
                  {option.ContactOwner}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item style={{ margin: "0px 0px 3px 0px" }}>
            {(tab === "Company" && (
              <Button
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                variant="contained"
                onClick={() => Navigate("AddCompanies")}
              >
                + Add more Companies
              </Button>
            )) ||
              (tab === "Contact" && (
                <Button
                  style={{
                    maxHeight: "22px",
                    minHeight: "22px",
                    textTransform: "none",
                    padding: "12px",
                  }}
                  variant="contained"
                  onClick={() => setContactAddOpen(true)}
                >
                  + Add more Contact
                </Button>
              )) ||
              (tab === "Branch" && (
                <Button
                  style={{
                    maxHeight: "22px",
                    minHeight: "22px",
                    textTransform: "none",
                    padding: "12px",
                  }}
                  variant="contained"
                  onClick={() =>
                    Navigate("AddCompanies", { state: { tab: "Branch" } })
                  }
                >
                  + Add more Branches
                </Button>
              ))}
          </Grid>
        </Grid>

        <Grid
          xs={12}
          container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Tab*/}
          <Grid item md={9.3} style={{ margin: "0px 0px 3px 0px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              variant={tab === "Company" ? "contained" : "text"}
              onClick={() => {
                localStorage.setItem("CompanyTab", "Company");
                settab("Company");
                setCActive("Active");
              }}
            >
              Companies
            </Button>

            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              variant={tab == "Branch" ? "contained" : "text"}
              onClick={() => {
                localStorage.setItem("CompanyTab", "Branch");
                settab("Branch");
                setCActive("Active");
              }}
            >
              Branches
            </Button>

            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              variant={tab == "Contact" ? "contained" : "text"}
              onClick={() => {
                localStorage.setItem("CompanyTab", "Contact");
                settab("Contact");
                setCActive("Active");
              }}
            >
              Contact
            </Button>
          </Grid>
          {/* Table*/}

          {(tab === "Company" &&
            (CActive === "Active" ? (
              <CompanyTable ContactOwner={OwnerId} />
            ) : (
              <Companyinactive ContactOwner={OwnerId} />
            ))) ||
            (tab === "Branch" &&
              (CActive === "Active" ? (
                <Branchtables ContactOwner={OwnerId} />
              ) : (
                <BranchInactive ContactOwner={OwnerId} />
              ))) ||
            (tab === "Contact" &&
              (CActive === "Active" ? (
                <Contacttable
                  ContactOwner={OwnerId}
                  Refresh={Refresh}
                  setRefresh={setRefresh}
                />
              ) : (
                <ContactInactive
                  ContactOwner={OwnerId}
                  Refresh={Refresh}
                  setRefresh={setRefresh}
                />
              )))}
        </Grid>
      </Grid>
      {ContactAddOpen && (
        <Addmorcontact
          Refresh={Refresh}
          setRefresh={setRefresh}
          ContactAddOpen={ContactAddOpen}
          setContactAddOpen={setContactAddOpen}
        />
      )}
    </div>
  );
}
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};
