import React from "react";
import { useEffect } from "react";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import { useDemoData } from "@mui/x-data-grid-generator";
import moment from "moment";
import {
  DataGridPremium,
  GridSearchIcon,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { Button, Dialog, DialogActions, Tooltip } from "@mui/material";
import { Box, DialogTitle, Grid, Paper } from "@material-ui/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ViewIcon } from "../../Assets/Icon";
import { ToastContainer, toast } from "react-toastify";

const CNewTable = ({ CreatedCount, slacounts }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const Navigate = useNavigate();
  //UseStates
  const [New, setNew] = useState([]);
  const [NewSearch, setNewSearch] = useState([]);
  const [TicketId, setTicketId] = useState("");

  //Dialog
  const CancelTicket = [{ ProcessId: "6", ProcessName: "Cancel" }];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const NewList = async () => {
    const response = await global.$axios
      .post(`ticket/cNewTickets/${AppCode}/${userId}`)
      .then(async (response) => {
        if (response.data.Status === 200) {
          setNew(response.data.Message);
          setNewSearch(response.data.Message);
        }
        // console.log("new", response.data);
      })
      .catch((e) => {
        console.error("Created New List ", e);
      });
  };

  //Cancel
  const Cancel = async () => {
    var CancelTicketId = CancelTicket.map((e) => e.ProcessId);
    const response = await global.$axios
      .put(`/ticket/statusChange`, {
        AppCode: AppCode,
        TicketId: TicketId,
        Status: CancelTicketId,
        Description: "Hi",
        UserId: userId,
        Attachment: "Hi",
      })
      .then(async (response) => {
        if (response.data.Status === 200) {
          toast.success("Ticket Cancelled");
          NewList();
          CreatedCount();
          slacounts();
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("Cancel Ticket Issue");
        toast.error("Something Went Wrong");
      });
  };

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = New.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const UpdatedAt = String(
          moment(item?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        const Description = String(item?.TicketDescription);
        const AssignedBy = String(item?.assigned?.UserFirstName);
        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedAt.toLowerCase().indexOf(text_data) > -1 ||
          Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedBy.toLowerCase().indexOf(text_data) > -1
        );
      });
      // if (!ticketData.length) {
      //   ticketData = New.filter((item) => {
      //     const TicketId = String(item.TicketId);
      //     const OwnerName = item?.dept?.DepartementName;
      //     const text_data = term;
      //     return TicketId.includes(text_data) || OwnerName.includes(text_data);
      //   });
      // }
      setNewSearch(ticketData);
    } else {
      setNewSearch(New);
    }
  }

  //Mui Premium
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "asc" }],
      },
    },
  });

  function UpdatedFullName(fields) {
    return `${fields.row.updated.UserFirstName || ""} ${
      fields.row.updated.UserLastName || ""
    }`;
  }
  function AssignedFullName(fields) {
    return `${fields?.row?.assigned?.UserFirstName || ""} ${
      fields?.row?.assigned?.UserLastName || ""
    }`;
  }

  const columns = [
    {
      field: "TicketId",
      headerName: "ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    {
      field: "AttachmentFile" || "",
      headerName: "Actions ",
      width: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip style={{ paddingRight: "5px" }} title={"Delete Ticket"}>
              <DeleteForeverSharpIcon
                fontSize="medium"
                onClick={() => {
                  // setdeletedata(cellValues?.row?.TicketId);
                  setTicketId(cellValues?.row?.TicketId);
                  handleOpen();
                }}
              />
            </Tooltip>
            <Tooltip style={{ paddingRight: "5px" }} title={"Edit Ticket"}>
              <EditSharpIcon
                fontSize="small"
                onClick={() =>
                  Navigate("/app/EditTicket", {
                    state: { data: cellValues.row },
                  })
                }
              />
            </Tooltip>
            <Tooltip title="View Ticket">
              <ViewIcon
                style={{ width: "20px", height: "20px" }}
                onClick={() =>
                  Navigate("/app/CreatedView", {
                    state: { data: cellValues.row },
                  })
                }
              />
            </Tooltip>
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.account?.CompanyName,
    },
    {
      field: "ContactName",
      headerName: "Contact Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName,
    },
    {
      field: "Department",
      headerName: "Department",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.dept?.DepartmentName,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
    },
    {
      field: "TicketDescription",
      headerName: "Description",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        var space = fields?.row?.TicketDescription.replace(/<[^>]+>/g, "");
        var format = space.replace(/\&nbsp;/g, "");
        return format || "";
      },
    },
    {
      field: "ECAt",
      headerName: " Time To Complete",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.ECAt).format("DD-MM-YYYY hh:mm A") || "",
    },
    {
      field: "Assigned To",
      headerName: "Assigned To ",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: AssignedFullName,
    },

    {
      field: "Updated At",
      headerName: "Created At",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.CreatedAt, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YYYY hh:mm A"
        ) || "",
    },
  ];

  useEffect(() => {
    NewList();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
          New
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            List
          </span>
        </p>
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
            }}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <Paper sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
            }}
            rowHeight={34}
            pagination={true}
            pageSize={10}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            {...data}
            apiRef={apiRef}
            columns={columns}
            rows={NewSearch ? NewSearch : []}
            getRowId={(row) => row.TicketId}
            loading={loading}
            disableRowSelectionOnClick
            initialState={initialState}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            slots={{ toolbar: GridToolbar }}
          />
        </Box>
      </Paper>

      <div>
        <Dialog
          // fullWidth
          maxWidth={"xs"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontWeight: "400",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                margin: "0px",
              }}
            >
              Sure You Want To Delete This Ticket No.
              <span style={{ color: "darkred" }}>{TicketId}</span>
            </p>{" "}
          </DialogTitle>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "0px 0px 10px 0px",
            }}
          >
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={() => {
                Cancel();
                handleClose();
              }}
              autoFocus
            >
              Yes
            </Button>
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={handleClose}
            >
              No{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
};

export default CNewTable;
