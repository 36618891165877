import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import clsx from "clsx";

import { useDemoData } from "@mui/x-data-grid-generator";
import { useState } from "react";
import dayjs from "dayjs";
import { useEffect } from "react";
import DateRangeSharpIcon from "@mui/icons-material/DateRangeSharp";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import TodayTwoToneIcon from "@mui/icons-material/TodayTwoTone";
import {
  DataGridPremium,
  GridSearchIcon,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DNewReport from "./ReportComponents/Daily Reports/DNewReport";
import DProgressReport from "./ReportComponents/Daily Reports/DProgressReport";
import DHoldReport from "./ReportComponents/Daily Reports/DHoldReports";
import DCompletedReport from "./ReportComponents/Daily Reports/DCompletedReports";
import DSolvedReport from "./ReportComponents/Daily Reports/DSolvedReports";
import { DateRangePicker } from "@mui/x-date-pickers-pro";

function DailyReport() {
  const AppCode = global.$userData.logindata.AppCode;
  const [Report, setReport] = useState("All");
  const [DTotal, setDTotal] = useState("");

  const [SLACount, setSLACount] = useState("");

  //UseStates
  const [DailyRow, setDailyRow] = useState([]);
  const [DailySearch, setDailySearch] = useState([]);
  //Department
  const [DepartmentShow, setDepartmentShow] = useState(null);
  var LDepart = localStorage.getItem("DailyDepart");

  const [DepartmentDrop, setDepartmentDrop] = useState(null);
  const [DepartmentAll, setDepartmentAll] = useState([]);
  //User

  const [UserShow, setUserShow] = useState({ UserId: "All", UserName: "All" });
  const [UserDrop, setUserDrop] = useState([]);
  const [UserAll, setUserAll] = useState([]);
  var AUID = UserAll.map((e) => e?.UserId);
  var LDate = localStorage.getItem("DailyTime");
  var spli = LDate && LDate.split(",");
  //Date Button
  var today = dayjs();
  var prevMonth = today.subtract(1, "month");
  var prevWeek = today.subtract(1, "year");

  const [Date, setDate] = useState([dayjs(), dayjs()]);
  const [Date1, setDate1] = useState([
    spli !== null ? dayjs(spli[0]) : dayjs(),
    spli !== null ? dayjs(spli[1]) : dayjs(),
  ]);

  var DForm =
    Date[0] &&
    `${Date[0].$d.getFullYear()}-${
      Date[0].$d.getMonth() + 1 < 10
        ? "0" + (Date[0].$d.getMonth() + 1)
        : Date[0].$d.getMonth() + 1
    }-${
      Date[0].$d.getDate() < 10
        ? "0" + Date[0].$d.getDate()
        : Date[0].$d.getDate()
    }`;
  var DForm1 =
    Date[1] &&
    `${Date[1].$d.getFullYear()}-${
      Date[1].$d.getMonth() + 1 < 10
        ? "0" + (Date[1].$d.getMonth() + 1)
        : Date[1].$d.getMonth() + 1
    }-${
      Date[1].$d.getDate() < 10
        ? "0" + Date[1].$d.getDate()
        : Date[1].$d.getDate()
    }`;
  //APIs
  const DepartmentList = async () => {
    const response = global.$axios
      .get(`/ticket/department/${AppCode}`)
      .then(async (response) => {
        setDepartmentDrop([
          { DepartmentId: "All", DepartmentName: "All" },
          ...response.data.Message,
        ]);
        setDepartmentAll(response.data.Message);
        setDepartmentShow(
          LDepart === null || JSON.parse(LDepart)?.DepartmentId == "All"
            ? { DepartmentId: "All", DepartmentName: "All" }
            : JSON.parse(LDepart)
        );

        UserList(
          LDepart === null || JSON.parse(LDepart)?.DepartmentId == "All"
            ? response?.data?.Message.map((e) => e.DepartmentId)
            : [JSON.parse(LDepart)?.DepartmentId]
        );
      })
      .catch((e) => {
        console.error("Daily Department Issue", e);
      });
  };

  const UserList = async (DeptId) => {
    const response = global.$axios
      .put(`/ticket/team/user/${AppCode}`, {
        DepartmentId: DeptId,
      })
      .then(async (response) => {
        var LUser = localStorage.getItem("DailyUsers");

        setUserDrop([
          { UserId: "All", UserName: "All" },
          ...response.data.Message,
        ]);
        setUserAll(response.data.Message);

        setUserShow(
          LUser == null || JSON.parse(LUser)?.UserId == "All"
            ? { UserId: "All", UserName: "All" }
            : JSON.parse(LUser)
        );
        DTotalCount(
          DeptId,
          LUser == null || JSON.parse(LUser)?.UserId == "All"
            ? response?.data?.Message.map((e) => e.UserId)
            : [JSON.parse(LUser)?.UserId],
          spli != null ? spli[0] : DForm,
          spli != null ? spli[1] : DForm1
        );
        SlaCount(
          DeptId,
          LUser == null || JSON.parse(LUser)?.UserId == "All"
            ? response?.data?.Message.map((e) => e.UserId)
            : [JSON.parse(LUser)?.UserId],
          spli != null ? spli[0] : DForm,
          spli != null ? spli[1] : DForm1
        );
        DailyList(
          DeptId,
          LUser == null || JSON.parse(LUser)?.UserId == "All"
            ? response?.data?.Message.map((e) => e.UserId)
            : [JSON.parse(LUser)?.UserId],
          spli != null ? spli[0] : DForm,
          spli != null ? spli[1] : DForm1
        );
      })
      .catch((e) => {
        console.error("Daily User Issue", e);
      });
  };

  const DTotalCount = async (DeptId, UserId, FromD, ToD) => {
    const response = await global.$axios
      .post(`/ticket/daycount/${AppCode}`, {
        CreatedAt: DForm,
        UserId: UserId == "All" ? UserAll.map((e) => e?.UserId) : UserId,
        DepartmentId:
          DeptId == "All" ? DepartmentAll.map((e) => e?.DepartmentId) : DeptId,
        frequency: 0,
        fromday: FromD,
        currentday: ToD,
      })
      .then(async (response) => {
        setDTotal(response.data.Message[0]);
      });
  };

  const SlaCount = async (DeptId, UserId, FromD, ToD) => {
    const response = await global.$axios
      .post(`/ticket/daySLA`, {
        UserId: UserId == "All" ? UserAll.map((e) => e?.UserId) : UserId,
        AppCode: AppCode,
        DepartmentId:
          DeptId == "All" ? DepartmentAll.map((e) => e?.DepartmentId) : DeptId,
        frequency: 0,
        fromday: FromD,
        currentday: ToD,
      })
      .then(async (response) => {
        setSLACount(response.data.Message[0]);
      });
  };

  const DailyList = async (DepId, useId, FromD, ToD) => {
    const response = await global.$axios
      .post(`/ticket/daywise/${AppCode}`, {
        DepartmentId:
          DepId == "All" ? DepartmentAll.map((e) => e?.DepartmentId) : DepId,
        UserId: useId == "All" ? UserAll.map((e) => e?.UserId) : useId,
        fromday: FromD,
        currentday: ToD,
        frequency: 0,
      })

      .then(async (response) => {
        setDailyRow(response.data.Message);
        setDailySearch(response.data.Message);
      })
      .catch((e) => {
        console.error("Daily List Issue", e);
      });
  };

  //APIs

  //Mui Premium

  //Default Sorting
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  const columns = [
    {
      field: "TicketId",
      headerName: "ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
      cellClassName: (params) => {
        let startDate = dayjs();
        var date = `${startDate.$d.getFullYear()}-${
          startDate.$d.getMonth() + 1 < 10
            ? "0" + (startDate.$d.getMonth() + 1)
            : startDate.$d.getMonth() + 1
        }-${
          startDate.$d.getDate() < 10
            ? "0" + startDate.$d.getDate()
            : startDate.$d.getDate()
        }`;
        var date3 = moment(params.row.ECAt, "YYYY-MM-DD hh:mm A").format(
          "YYYY-MM-DD"
        );
        var date4 = moment(
          params.row.TicketClosedTime,
          "YYYY-MM-DD hh:mm A"
        ).format("YYYY-MM-DD");
        return clsx("super-app", {
          Sla: date3 < date && date4 > date3,
        });
      },
    },
    // {
    //   field: "AttachmentFile" || "",
    //   headerName: "Actions",
    //   width: 80,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (cellValues) => {
    //     return (
    //       <>
    //         <Tooltip title="View Ticket">
    //           <ViewIcon
    //             style={{ width: "20px", height: "20px" }}
    //             onClick={() =>
    //               Navigate("/app/TicketView", {
    //                 state: { data: cellValues.row },
    //               })
    //             }
    //           />
    //         </Tooltip>
    //       </>
    //     );
    //   },
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 300,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.account?.CompanyName,
    },
    {
      field: "ContactName",
      headerName: "Contact Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
    },
    {
      field: "TicketDescription",
      headerName: "Description",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        var space = fields?.row?.TicketDescription.replace(/<[^>]+>/g, "");
        var format = space.replace(/\&nbsp;/g, "");
        return format || "";
      },
    },
    {
      field: "Status" || "",
      headerName: "Status",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ECAt",
      headerName: "Time To Complete",
      width: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        fields?.row?.ECAt
          ? moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YY hh.mm A"
            )
          : "-",
    },

    {
      field: "TicketClosedTime" || "",
      headerName: "Closed Time",
      width: 190,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.TicketClosedTime !== ""
          ? moment(fields?.row?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YYYY hh:mm A"
            )
          : "-";
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created" || "",
      headerName: "Assigned By",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        fields?.row?.created
          ? `${fields?.row?.created?.UserFirstName || ""} ${
              fields?.row?.created?.UserLastName || ""
            }`
          : fields?.row?.contact?.ContactName,
    },
    {
      field: "assigned" || "",
      headerName: "Assigned To",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return `${fields?.row?.assigned?.UserFirstName || ""} ${
          fields?.row?.assigned?.UserLastName || ""
        }`;
      },
    },
  ];
  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    maxColumns: 6,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "desc" }],
      },
    },
  });

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = DailyRow.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const ETC = String(
          moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
        );
        const ClosedTime = String(
          moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        const Description = String(item?.TicketDescription);
        const AssignedBy = String(item?.created?.UserFirstName);
        const AssignedTo = String(item?.assigned?.UserFirstName);
        const Status = String(item?.Status);

        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
          Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
          AssignedTo.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1 ||
          Status.toLowerCase().indexOf(text_data) > -1
        );
      });
      // if (!ticketData.length) {
      //   ticketData = New.filter((item) => {
      //     const TicketId = String(item.TicketId);
      //     const OwnerName = item?.dept?.DepartementName;
      //     const text_data = term;
      //     return TicketId.includes(text_data) || OwnerName.includes(text_data);
      //   });
      // }
      setDailySearch(ticketData);
    } else {
      setDailySearch(DailyRow);
    }
  }

  useEffect(() => {
    DepartmentList();

    // setReport(
    //   localStorage.getItem("Daily Tab") === null
    //     ? "All"
    //     : localStorage.getItem("Daily Tab")
    // );
  }, []);

  return (
    <>
      <Grid container xs={12}>
        <div>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("Daily Tab", "All");
              setReport("All");
            }}
          >
            Reports
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            Daywise Ticket Status
          </span>
        </div>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          marginTop: "15px",
        }}
      >
        <Grid
          container
          item
          md={11}
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item md={3.5} xs={12} sx={{ marginBottom: "5px" }}>
            <Autocomplete
              fullWidth
              id="auto-complete"
              size="small"
              disableClearable
              options={DepartmentDrop}
              value={DepartmentShow}
              isOptionEqualToValue={(option, value) =>
                option.DepartmentName == value.DepartmentName
              }
              onChange={async (event, newValue) => {
                localStorage.removeItem("DailyUsers");
                if (newValue.DepartmentId == "All") {
                  UserList(DepartmentAll.map((item) => item?.DepartmentId));
                  localStorage.removeItem("DailyUsers");
                  setUserShow({ UserId: "All", UserName: "All" });
                  DailyList(
                    DepartmentAll.map((item) => item?.DepartmentId),
                    UserAll.map((e) => e?.UserId),
                    LDate !== null ? LDate : DForm
                  );
                } else {
                  localStorage.removeItem("DailyUsers");
                  await UserList([newValue?.DepartmentId]);

                  setUserShow({ UserId: "All", UserName: "All" });
                  DailyList(
                    [newValue?.DepartmentId],
                    AUID,
                    LDate !== null ? LDate : DForm
                  );
                }
                localStorage.setItem("DailyDepart", JSON.stringify(newValue));
                setDepartmentShow(newValue);
              }}
              getOptionLabel={(option) => option.DepartmentName}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.DepartmentId}>
                  {option.DepartmentName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>
          <Grid item md={3.5} xs={12} sx={{ marginBottom: "5px" }}>
            <Autocomplete
              fullWidth
              id="auto-complete"
              size="small"
              disableClearable
              options={UserDrop}
              value={UserShow}
              isOptionEqualToValue={(option, value) =>
                option.UserName == value.UserName
              }
              onChange={(event, newValue) => {
                setUserShow(newValue);
                if (
                  DepartmentShow?.DepartmentId == "All" &&
                  newValue?.UserId == "All"
                ) {
                  DailyList(
                    DepartmentAll.map((item) => item?.DepartmentId),
                    UserAll.map((e) => e?.UserId),
                    spli != null ? spli[0] : DForm,
                    spli != null ? spli[1] : DForm1
                  );
                  DTotalCount(
                    DepartmentAll.map((item) => item?.DepartmentId),
                    UserAll.map((e) => e?.UserId),
                    spli != null ? spli[0] : DForm,
                    spli != null ? spli[1] : DForm1
                  );
                  SlaCount(
                    DepartmentAll.map((item) => item?.DepartmentId),
                    UserAll.map((e) => e?.UserId),
                    spli != null ? spli[0] : DForm,
                    spli != null ? spli[1] : DForm1
                  );
                } else {
                  DailyList(
                    [DepartmentShow?.DepartmentId],
                    [newValue?.UserId],
                    spli != null ? spli[0] : DForm,
                    spli != null ? spli[1] : DForm1
                  );
                  DTotalCount(
                    [DepartmentShow?.DepartmentId],
                    [newValue?.UserId],
                    spli != null ? spli[0] : DForm,
                    spli != null ? spli[1] : DForm1
                  );
                  SlaCount(
                    [DepartmentShow?.DepartmentId],
                    [newValue?.UserId],
                    spli != null ? spli[0] : DForm,
                    spli != null ? spli[1] : DForm1
                  );
                  localStorage.setItem("DailyUsers", JSON.stringify(newValue));
                }
              }}
              getOptionLabel={(option) => option.UserName}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.UserId}>
                  {option.UserName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Users"
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>
          <Grid
            container
            item
            md={4.5}
            xs={12}
            sx={{
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item md={9} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  value={Date1}
                  disableFuture
                  calendars={3}
                  format="DD-MM-YYYY"
                  onChange={(newValue) => {
                    localStorage.setItem(
                      "DailyTime",
                      `${newValue[0].$d},${
                        newValue[1] != null ? newValue[1].$d : newValue[0].$d
                      }`
                    );
                    setDate(newValue);
                    setDate1(newValue);
                    DailyList(
                      [DepartmentShow?.DepartmentId],
                      [UserShow?.UserId],
                      newValue[0] && newValue[0].$d,
                      newValue[1] && newValue[1].$d
                    );
                    DTotalCount(
                      [DepartmentShow?.DepartmentId],
                      [UserShow?.UserId],
                      newValue[0] && newValue[0].$d,
                      newValue[1] && newValue[1].$d
                    );
                    SlaCount(
                      [DepartmentShow?.DepartmentId],
                      [UserShow?.UserId],
                      newValue[0] && newValue[0].$d,
                      newValue[1] && newValue[1].$d
                    );
                  }}
                  label="DD-MM-YYYY"
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              md={3}
              xs={12}
              item
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  localStorage.setItem(
                    "DailyTime",
                    `${today.subtract(7, "day").$d},${
                      today != null ? today.$d : today.subtract(7, "day").$d
                    }`
                  );
                  DailyList(
                    [DepartmentShow?.DepartmentId],
                    [UserShow?.UserId],
                    today.subtract(7, "day").$d,
                    today.$d
                  );
                  DTotalCount(
                    [DepartmentShow?.DepartmentId],
                    [UserShow?.UserId],
                    today.subtract(7, "day").$d,
                    today.$d
                  );
                  SlaCount(
                    [DepartmentShow?.DepartmentId],
                    [UserShow?.UserId],
                    today.subtract(7, "day").$d,
                    today.$d
                  );
                  setDate1([today.subtract(7, "day"), today]);
                  setDate([today.subtract(7, "day"), today]);
                }}
              >
                <Tooltip title="Last 7 Days">
                  <TodayTwoToneIcon sx={{ mt: "2px" }} color="secondary" />
                </Tooltip>
              </IconButton>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  localStorage.setItem(
                    "DailyTime",
                    `${prevMonth.startOf("month").$d},${
                      prevMonth.endOf("month") != null
                        ? prevMonth.endOf("month").$d
                        : prevMonth.startOf("month").$d
                    }`
                  );
                  DailyList(
                    [DepartmentShow?.DepartmentId],
                    [UserShow?.UserId],
                    prevMonth.startOf("month").$d,
                    prevMonth.endOf("month").$d
                  );
                  DTotalCount(
                    [DepartmentShow?.DepartmentId],
                    [UserShow?.UserId],
                    prevMonth.startOf("month").$d,
                    prevMonth.endOf("month").$d
                  );
                  SlaCount(
                    [DepartmentShow?.DepartmentId],
                    [UserShow?.UserId],
                    prevMonth.startOf("month").$d,
                    prevMonth.endOf("month").$d
                  );
                  setDate1([
                    prevMonth.startOf("month"),
                    prevMonth.endOf("month"),
                  ]);
                  setDate([
                    prevMonth.startOf("month"),
                    prevMonth.endOf("month"),
                  ]);
                }}
              >
                <Tooltip title="Last Month">
                  <CalendarMonthSharpIcon color="success" />
                </Tooltip>
              </IconButton>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  localStorage.setItem(
                    "DailyTime",
                    `${prevWeek.startOf("year").$d},${
                      prevWeek.endOf("year") != null
                        ? prevWeek.endOf("year").$d
                        : prevWeek.startOf("year").$d
                    }`
                  );
                  DailyList(
                    [DepartmentShow?.DepartmentId],
                    [UserShow?.UserId],
                    prevWeek.startOf("year").$d,
                    prevWeek.endOf("year").$d
                  );
                  DTotalCount(
                    [DepartmentShow?.DepartmentId],
                    [UserShow?.UserId],
                    prevWeek.startOf("year").$d,
                    prevWeek.endOf("year").$d
                  );
                  SlaCount(
                    [DepartmentShow?.DepartmentId],
                    [UserShow?.UserId],
                    prevWeek.startOf("year").$d,
                    prevWeek.endOf("year").$d
                  );
                  setDate1([prevWeek.startOf("year"), prevWeek.endOf("year")]);
                  setDate([prevWeek.startOf("year"), prevWeek.endOf("year")]);
                }}
              >
                <Tooltip title="Last Year">
                  <DateRangeSharpIcon color="primary" />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "5px 0px 0px 0px",
        }}
      >
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Daily Tab", "New");
            setReport("New");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              New
              <span style={{ fontSize: "8px", color: "darkred" }}>/ Delay</span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {DTotal.New}
              <span
                style={{
                  margin: "0px",
                  fontSize: "12px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / {SLACount?.New}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Daily Tab", "Progress");
            setReport("Progress");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Progress
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {DTotal.Progress}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Progress}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Daily Tab", "Hold");
            setReport("Hold");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Hold
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {DTotal.Holding}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Holding}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Daily Tab", "Completed");
            setReport("Completed");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Completed
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {DTotal.Completed}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Completed}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Daily Tab", "Solved");
            setReport("Solved");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Solved
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {DTotal.Solved}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Solved}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid item md={3.9} sm={5.9} xs={12}></Grid>
      </Grid>
      {Report == "All" && (
        <>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
              All
              <span
                style={{
                  paddingLeft: "5px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                List
              </span>
            </p>
            <Box
              id="searcBox"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "3px",
                background: "#FFFFFF",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#2323234D",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              <input
                placeholder="Search..."
                type="text"
                className="searcInput"
                id="searchInput"
                style={{
                  outline: "none",
                  backgroundColor: "#FFFFFF",
                  border: "none",
                }}
                autoComplete="off"
                onChange={(e) => filterList(e.target.value)}
              />

              <GridSearchIcon style={{ color: "#2323234D" }} />
            </Box>
          </Grid>
          <Paper>
            <Box
              sx={{
                height: 500,
                width: "100%",
                "& .super-app-theme--header": {
                  color: "#0071BC",
                },
              }}
            >
              <DataGridPremium
                sx={{
                  ".MuiTablePagination-selectLabel": {
                    margin: "0px",
                  },
                  ".MuiTablePagination-displayedRows": {
                    margin: "0px",
                  },
                  "& .super-app.Sla": {
                    backgroundColor: "rgb(252,204,203)",
                  },
                  "& .super-app-theme--New": {
                    backgroundColor: "rgb(229,243,253)",
                    "&:hover": {
                      backgroundColor: "rgb(179,229,251)",
                    },
                  },
                }}
                rowHeight={34}
                pagination={true}
                pageSize={10}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                {...data}
                apiRef={apiRef}
                columns={columns}
                rows={DailySearch ? DailySearch : []}
                getRowId={(row) => row.TicketId}
                loading={loading}
                disableRowSelectionOnClick
                initialState={initialState}
                slots={{ toolbar: GridToolbar }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                getRowClassName={(params) =>
                  `super-app-theme--${params.row.Status}`
                }
              />
            </Box>
          </Paper>
        </>
      )}
      {Report === "New" && (
        <DNewReport
          DeptID={DepartmentShow}
          DID={DepartmentAll}
          UID={UserAll}
          UserID={UserShow}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {Report === "Progress" && (
        <DProgressReport
          DeptID={DepartmentShow}
          DID={DepartmentAll}
          UID={UserAll}
          UserID={UserShow}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {Report === "Hold" && (
        <DHoldReport
          DeptID={DepartmentShow}
          DID={DepartmentAll}
          UID={UserAll}
          UserID={UserShow}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {Report === "Completed" && (
        <DCompletedReport
          DeptID={DepartmentShow}
          DID={DepartmentAll}
          UID={UserAll}
          UserID={UserShow}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {Report === "Solved" && (
        <DSolvedReport
          DeptID={DepartmentShow}
          DID={DepartmentAll}
          UID={UserAll}
          UserID={UserShow}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
    </>
  );
}
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
    // borderBottom: "1px solid #0071BC",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 500,
    fontSize: "14px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
};

export default DailyReport;
