import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../Assets/Icon";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { ToastContainer, toast } from "react-toastify";
import { useMediaQuery } from "@material-ui/core";

function TicketTransfer({
  data,
  Transferopen,
  SetTransferopen,
  refresh,
  Setrefresh,
}) {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const handleClose = () => SetTransferopen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //dropdown
  const [Departementdrop, SetDepartmentDrop] = useState([]);
  const [UserDrop, SetUserdrop] = useState([]);
  //dropvalue
  const [Department, setdepartment] = useState("");
  const [user, setuser] = useState("");
  //api
  const Departementapi = async () => {
    const response = await global.$axios.get(`/ticket/department/${AppCode}`);
    SetDepartmentDrop(response.data.Message);
  };
  const UserApi = async (DeptId) => {
    const response = await global.$axios.get(
      `/ticket/assignto/${AppCode}/${DeptId.DepartmentId}`
    );
    SetUserdrop(response.data.Message);
  };
  const TicketTransfer = async () => {
    if (Department == "" || user == "") {
      toast.error("Please Fill The Requied Field");
      return;
    }
    const data1 = {
      AppCode: AppCode,
      DepartmentId: Department.DepartmentId,
      TicketOwner: user.UserId,
      TicketId: data.TicketId,
    };
    const response = await global.$axios.post(`ticket/team/transfer`, data1);
    if (response.data.Status == 200) {
      handleClose();
      toast.success("Ticket Transferred");
      Setrefresh(!refresh);
    } else {
      toast.error("SomeThing Went Wrong");
    }
  };

  useEffect(() => {
    Departementapi();
  }, [data]);

  function joinName() {
    return `${data.assigned.UserFirstName || ""} ${
      data.assigned.UserLastName || ""
    }`;
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"xs"}
        open={Transferopen}
        onClose={handleClose}
      >
        <Box sx={{ padding: "10px 20px 20px 20px" }}>
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                margin: "10px",
                color: "#0071BC",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              Ticket Transfer
            </div>
            <div>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent style={{ marginTop: "1px" }}>
            <Grid container md={12} xs={12}>
              <Grid
                item
                md={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <p
                    style={{
                      maxHeight: "22px",
                      minHeight: "22px",
                      fontSize: "14px",
                    }}
                  >
                    Department :{" "}
                    <span
                      style={{
                        color: "#0071BC",
                        fontWeight: 600,
                        fontSize: "22px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {data.dept.DepartmentName}
                    </span>
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      maxHeight: "22px",
                      minHeight: "22px",
                      fontSize: "14px",
                    }}
                  >
                    Assign To :
                    <span
                      style={{
                        color: "#0071BC",
                        fontWeight: 600,
                        fontSize: "22px",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {" "}
                      {joinName()}
                    </span>{" "}
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px 0px 10px 0px",
              }}
            >
              <ImportExportIcon fontSize="large" />
            </Grid>
            <Grid
              container
              md={12}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <div>
                <Autocomplete
                  id="combo-box-demo"
                  options={Departementdrop}
                  value={Department}
                  isOptionEqualToValue={(option, value) =>
                    option.DepartmentName == value.DepartmentName
                  }
                  disableClearable
                  getOptionLabel={(option) => option.DepartmentName || ""}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Department *" />
                  )}
                  onChange={(event, newvalue) => {
                    setdepartment(newvalue);
                    UserApi(newvalue);
                  }}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={UserDrop}
                  value={user}
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    option.UserName == value.UserName
                  }
                  getOptionLabel={(option) => option.UserName || ""}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Select User *" />
                  )}
                  onChange={(event, newvalue) => {
                    setuser(newvalue);
                  }}
                />
              </div>
              <center style={{ marginTop: "10px" }}>
                <Button
                  variant="contained"
                  onClick={() => TicketTransfer()}
                  style={{
                    maxHeight: "22px",
                    minHeight: "22px",
                    textTransform: "none",
                    padding: "12px",
                  }}
                >
                  Transfer
                </Button>
              </center>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
}

export default TicketTransfer;

const styles = {
  txt: {
    fontSize: "12px",
    margin: "0px",
  },

  hdr: {
    fontSize: "14px",
    margin: "0px",
    fontWeight: 500,
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
