import { Button, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import ProductSubInactiveTable from "./InActiveTables/ProductSubInactiveTable";
import TikcetCategoryTable from "./ActiveTables/TicketCategoryTable";
import TicketCategoryPopup from "./PopupScreens/TicketCategoryPopup";
import TicketCategoryinactiveTable from "./InActiveTables/TicketCategoryinactiveTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const TicketCategory = ({ setTab }) => {
  const Navigate = useNavigate();
  const [TicketCategoryOpen, setTicketCategoryOpen] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [sub, setSub] = useState("Active");
  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{
                  width: "20px",
                  height: "15px",
                  marginBottom: "3px",
                }}
              />
            </Tooltip>
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => Navigate(-1)}
          >
            Masters /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            {" "}
            Ticket Category
          </span>
        </div>
        <Button
          style={{
            maxHeight: "22px",
            minHeight: "22px",
            textTransform: "none",
            padding: "12px",
          }}
          size="small"
          variant="contained"
          onClick={() => setTicketCategoryOpen(true)}
        >
          + Add Ticket Category
        </Button>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          item
          md={9}
          sm={9}
          xs={12}
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            variant={sub === "Active" ? "contained" : "text"}
            onClick={() => setSub("Active")}
          >
            Active
          </Button>
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            variant={sub === "Inactive" ? "contained" : "text"}
            onClick={() => setSub("Inactive")}
          >
            Inactive
          </Button>
        </Grid>
        {(sub === "Active" && (
          <TikcetCategoryTable Refresh={Refresh} setRefresh={setRefresh} />
        )) ||
          (sub === "Inactive" && <TicketCategoryinactiveTable />)}
      </Grid>

      {TicketCategoryOpen && (
        <TicketCategoryPopup
          Refresh={Refresh}
          setRefresh={setRefresh}
          TicketCategoryOpen={TicketCategoryOpen}
          setTicketCategoryOpen={setTicketCategoryOpen}
        />
      )}
    </div>
  );
};

export default TicketCategory;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};
