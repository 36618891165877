import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Login = ({ setforget }) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      welcome();
    }
  };

  const welcome = async () => {
    if (!email) {
      toast.error("Please Provide Valid Mail_Id");

      return;
    }
    if (!password) {
      toast.error("Please Provide Correct Password");

      return;
    }
    let data = {
      EmailId: email,
      Password: password,
    };
    const response = await global.$axios.post("/auth/teamlogin", data);
    if (response.data.Status == "200") {
      dispatch({
        type: "SET_USER_IN",
        logindata: response.data.Message,
        payload: true,
      });
      setTimeout(() => {
        Navigate("/app/Dashboard");
      }, 1000);
      toast.success("Welcome..! Login Success");
    } else {
      toast.error("Please Check Your Credentials");
    }
  };

  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus={true}
          fullWidth
          id="username"
          placeholder="Mail Id"
          variant="outlined"
          type="email"
          size="small"
          style={{
            background: "rgba(255, 255, 255, 1)",
            marginTop: "10px",
            borderRadius: "5px",
          }}
        />

        <FormControl
          sx={{
            mt: "10px",
            width: "100%",
            background: "white",
            borderRadius: "5px",
          }}
          variant="outlined"
        >
          {/*  */}
          <OutlinedInput
            size="small"
            sx={{
              ".css-1uvydh2": { padding: "0px" },
            }}
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            placeholder="Password"
          />
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={<Checkbox />}
          label="Remember me"
          size="small"
          sx={{
            "& .MuiTypography-root ": {
              fontSize: 14,
              fontWeight: "500",
              color: "white",
            },
            "& .MuiSvgIcon-root": {
              fontSize: 20,
              padding: "0px",
              color: "white",
              margin: "0px",
            },
          }}
        />
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            cursor: "pointer",
            color: "white",
          }}
          onClick={() => {
            setforget("forget");
            if (email) {
              localStorage.setItem("loginemail", email);
            }
          }}
        >
          Forgot Password?
        </span>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          style={{
            minHeight: "35px",
            maxHeight: "35px",
            color: "rgb(45,113,188)",
            backgroundColor: "white",
            borderRadius: "10px",
            fontWeight: "700",
            marginTop: "10px",
            textTransform: "none",
          }}
          onClick={() => {
            welcome();
          }}
        >
          Login
        </Button>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userState: state.user,
  };
};
const mapDispatchToprops = (dispatch) => {
  return {
    login: (getUserDate) => {
      dispatch({ type: "SET_USER_IN", getUserDate });
    },
    logout: () => {
      dispatch({ type: "SET_USER_OUT" });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToprops)(Login);
