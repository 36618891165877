import { ToastContainer, toast } from "react-toastify";
import { Grid, TextField, Button, Autocomplete, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box } from "@mui/system";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import JoditEditor from "jodit-react";
import ProductAdd from "./ProductAdd";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

function EditTicket() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  //UseStates
  const editData = useLocation();
  const [showDepartments, setShowDepartments] = useState(true);
  const [company, setCompany] = useState(null);

  const [companyDrop, setCompanyDrop] = useState([]);
  const [contact, setContact] = useState(null);
  // console.log("contact", contact);
  const [contactDrop, setContactDrop] = useState([]);
  const [Department, setDepartment] = useState(null);
  const [DepartDrop, setDepartDrop] = useState([]);
  const deptDrop = [];
  const [ticketSource, setTicketSource] = useState(null);
  const [ticketDrop, setTicketDrop] = useState([]);
  const [Product, setProduct] = useState(null);
  const [productDrop, setProductDrop] = useState([]);
  const ProductSelect = [];
  const [Issues, setIssues] = useState(null);
  const [IssueDrop, setIssueDrop] = useState([]);
  const IssueSelect = [];
  const [showIssues, setShowIssues] = useState(false);
  const [assign, setAssign] = useState(null);
  const [assignDrop, setAssignDrop] = useState([]);
  const [showProduct, setShowProduct] = useState(false);
  const [TimeError, setTimeError] = useState(null);
  const [DT, setDT] = useState("");
  console.log("DT", DT);
  // let DForm = `${DT.$d.getFullYear()}-${
  //   DT.$d.getMonth() < 10 ? "0" + (DT.$d.getMonth() + 1) : DT.$d.getMonth() + 1
  // }-${DT.$d.getDate() < 10 ? "0" + DT.$d.getDate() : DT.$d.getDate()} ${
  //   DT.$d.getHours() < 10 ? "0" + DT.$d.getHours() : DT.$d.getHours()
  // }:${DT.$d.getMinutes() < 10 ? "0" + DT.$d.getMinutes() : DT.$d.getMinutes()}`;
  /* Jodit Editor*/
  const editor = useRef(null);
  const [content, setContent] = useState("");

  /*Department, product, issue onFocus */
  const [activeList, setActiveList] = useState("");
  const [productactiveList, setProductActiveList] = useState("");
  const [IssueActiveList, setIssueActiveList] = useState("");

  //Dynamic Product Add PopupOpen
  const [ProductAddOpen, setProductAddOpen] = useState(false);

  const Navigate = useNavigate();

  const handleDepartment = (val) => {
    setDepartment(val);
    setShowDepartments(val !== null ? false : true);
    setShowIssues(true);
  };
  const DepartmentShow = () => {
    setShowDepartments(true);
    setShowProduct(false);
    setShowIssues(false);
  };

  const handleProduct = (val) => {
    setProduct(val);
    setShowProduct(val !== null ? false : true);
    setShowIssues(false);
  };

  const ProductShow = () => {
    if (showProduct) {
      if (Department && Issues) {
        setShowProduct(true);
        setShowIssues(false);
        setShowDepartments(false);
      } else {
        setShowProduct(false);
      }
    } else {
      setShowProduct(true);
      setShowIssues(false);
      setShowDepartments(false);
    }
  };

  const handleIssues = (val) => {
    setIssues(val);
    setShowIssues(val !== null ? false : true);
  };

  const IssueShow = () => {
    if (Department) {
      setShowIssues(true);
      setShowDepartments(false);
      setShowProduct(false);
    } else {
      setShowIssues(false);
    }
  };

  const companyList = async () => {
    const response = await global.$axios.get(`/ticket/companies/${AppCode}`);
    setCompanyDrop(response.data.Message);
  };

  const contactList = async (AccountId) => {
    if (!AccountId) {
      setContactDrop([]);
      setContact(null);
      setProductDrop([]);
      setProduct(null);
    } else {
      const response = await global.$axios.get(
        `/ticket/contacts/${AppCode}/${AccountId}`
      );
      setContactDrop(response.data.Message);
      setContact(response.data.Message[0]);
      productList(response.data.Message[0].ContactId);
    }
  };

  const ticketList = async () => {
    const response = await global.$axios.get(`/source/activelist/${AppCode}`);
    setTicketDrop(response.data.Message);
    setTicketSource(response.data.Message[0]);
  };

  const departmentList = async () => {
    const response = await global.$axios.get(`ticket/department/${AppCode}`);
    setDepartDrop(response.data.Message);
  };

  const productList = async (ContactId) => {
    if (!ContactId) {
      setProductDrop([]);
      setProduct(null);
    }
    const response = await global.$axios.get(
      `/ticket/products/${AppCode}/${ContactId}`
    );
    setShowProduct(true);
    setProductDrop(
      response.data.Message.length > 0 ? response.data.Message : []
    );
    setProduct(null);
    setProductActiveList("");
  };
  const issueList = async (deptid) => {
    if (!deptid || deptid != deptid) {
      setIssueDrop([]);
      setIssues(null);
    }
    const response = await global.$axios.get(
      `/ticket/issue/${AppCode}/${deptid}`
    );
    // console.log("issueList", response.data);
    setIssueDrop(response.data.Message);
    setIssues(null);
    setIssueActiveList("");
  };

  const assignList = async (deptid) => {
    if (!deptid) {
      setAssignDrop([]);
      setAssign(null);
    }
    const response = await global.$axios.get(
      `/ticket/assignto/${global.$userData.logindata.AppCode}/${deptid}`
    );
    setAssignDrop(response.data.Message);
    setAssign(null);
  };

  //ETC
  const ETC = async (IssueId, DeptId) => {
    const response = await global.$axios.post(`/ticket/getetc`, {
      AppCode: AppCode,
      IssueId: IssueId,
      FuctionFor: "Ticket",
      DepartmentId: DeptId,
    });
    const res = dayjs(response.data.Message);
    setDT(res);
  };

  //Edit View
  const EditView = async () => {
    const response = await global.$axios.get(
      `/ticket/view/${AppCode}/${editData.state.data.TicketId}`
    );
    // console.log("EditView", response.data);
    var res = response?.data?.ticketdetails;
    setCompany(res?.account);
    await contactList(res?.account?.AccountsId);
    setContact(res?.contact);
    await productList(res?.contact?.ContactId);
    setTicketSource(res?.ticket_source);
    setDepartment(res?.dept);
    await issueList(res?.dept?.DepartmentId);
    await assignList(res?.dept?.DepartmentId);
    console.log("etcvalue", dayjs(res?.ECAt));
    setDT(dayjs(res?.ECAt));
    setActiveList(res?.dept?.DepartmentName);
    setProduct(res?.product);
    setProductActiveList(res?.product?.ProductName);
    setIssues(res?.issue);
    setIssueActiveList(res?.issue?.IssueName);
    setAssign(res?.assigned);
    setContent(res?.TicketDescription);
  };

  //Ticket Update

  const UpdateTicket = async () => {
    let DForm = `${DT.$d.getFullYear()}-${
      DT.$d.getMonth() < 10
        ? "0" + (DT.$d.getMonth() + 1)
        : DT.$d.getMonth() + 1
    }-${DT.$d.getDate() < 10 ? "0" + DT.$d.getDate() : DT.$d.getDate()} ${
      DT.$d.getHours() < 10 ? "0" + DT.$d.getHours() : DT.$d.getHours()
    }:${
      DT.$d.getMinutes() < 10 ? "0" + DT.$d.getMinutes() : DT.$d.getMinutes()
    }`;
    if (
      AppCode === "" ||
      !(company != null && Object.values(company).length !== 0) ||
      !(contact != null && Object.values(contact).length !== 0) ||
      !(Department != null && Object.values(Department).length !== 0) ||
      !(Issues != null && Object.values(Issues).length !== 0) ||
      !(assign != null && Object.values(assign).length !== 0) ||
      content === ""
    ) {
      toast.error("You Missed Required Fields");
      return;
    } else {
      const response = await global.$axios.put(
        `/ticket/update/${editData.state.data.TicketId}`,
        {
          AppCode: AppCode,
          AccountId: company.AccountsId,
          ContactId: contact.ContactId,
          TicketSourceId: ticketSource.TicketSourceId,
          DepartmentId: Department.DepartmentId,
          ProductId: Product.ProductId,
          IssueId: Issues.IssueId,
          ECAt: DForm,
          TicketDescription: content,
          UserId: userId,
          TicketOwner: assign.UserId,
        }
      );

      if (response.data.Status === 200) {
        toast.success("Update Success");
        setTimeout(() => {
          Navigate(-1);
        }, 1500);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    companyList();
    ticketList();
    departmentList();
    EditView();
  }, []);
  return (
    <>
      <Grid container>
        <Grid item lg={3} xs={12} style={{ margin: "5px 0px" }}>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{
                  width: "20px",
                  height: "15px",
                  marginBottom: "3px",
                }}
              />
            </Tooltip>
          </span>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              fontSize: "16px",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Created /{" "}
          </span>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "20px",
              margin: "0px",
              padding: "0px",
            }}
          >
            Edit Ticket
          </span>
        </Grid>

        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0px 0px 0px",
          }}
        >
          <Grid item md={3.5} sm={7} xs={12} style={{ marginBottom: "5px" }}>
            <span style={{ fontSize: "14px", fontWeight: 500 }}>
              Company Name
            </span>
            <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>

            <Autocomplete
              id="auto-complete"
              size="small"
              options={companyDrop}
              value={company}
              onChange={(event, newValue) => {
                if (!newValue) {
                  contactList(null);
                } else {
                  contactList(newValue.AccountsId);
                }
                setCompany(newValue);
              }}
              getOptionLabel={(option) => option.CompanyName}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.AccountsId}>
                  {option.CompanyName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  // error
                  {...params}
                  placeholder="Company Name"
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>
          <Grid item md={3.5} sm={7} xs={12} style={{ marginBottom: "5px" }}>
            <span style={{ fontSize: "14px", fontWeight: 500 }}>
              Contact Name
            </span>
            <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>

            <Autocomplete
              size="small"
              id="combo-box-demo"
              value={contact}
              options={contactDrop}
              getOptionLabel={(option) => option.contactname}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.ContactId}>
                  {option.contactname}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Contact Name"
                  variant="outlined"
                  required
                />
              )}
              onChange={(event, newValue) => {
                if (!newValue) {
                  productList(null);
                } else {
                  productList(newValue?.ContactId);
                }
                setContact(newValue);
              }}
            />
          </Grid>
          <Grid item md={3.5} sm={7} xs={12} style={{ marginBottom: "5px" }}>
            <span style={{ fontSize: "14px", fontWeight: 500 }}>
              Ticket Source
            </span>
            <Autocomplete
              size="small"
              defaultValue={ticketDrop.TicketSourceId}
              id="combo-box-demo"
              value={ticketSource}
              options={ticketDrop}
              getOptionLabel={(option) => option.TicketSourceName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select"
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                setTicketSource(newValue);
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            cursor: "pointer",
            margin: "5px 0px 0px 0px",
          }}
        >
          <span
            style={style5.title}
            onClick={() => {
              DepartmentShow();
            }}
          >
            Department{" "}
          </span>
          <span style={{ color: "darkred" }}>*</span>
          {Department ? (
            <span
              style={style5.title2}
              onClick={() => setShowDepartments(false)}
            >
              / {Department.DepartmentName}
            </span>
          ) : null}
        </div>
        {showDepartments ? (
          <Grid container xs={12} style={{}}>
            <Grid
              container
              item
              lg={8.5}
              xs={12}
              style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
            >
              {DepartDrop.map((Department, index) => {
                if (index <= 5) {
                  return (
                    <Grid
                      item
                      md={1.7}
                      sm={8}
                      xs={12}
                      style={
                        activeList === Department.DepartmentName
                          ? style5.cardsActive
                          : style5.cards
                      }
                      onClick={() => {
                        if (!Department.DepartmentId) {
                          assignList(null);
                          issueList(null);
                        } else {
                          assignList(Department.DepartmentId);
                          issueList(Department.DepartmentId);
                        }
                        handleDepartment(Department);
                        setActiveList(Department.DepartmentName);
                      }}
                      key={index}
                    >
                      {Department.DepartmentName.substr(0, 20)}
                    </Grid>
                  );
                } else {
                  deptDrop?.push(Department);
                }
              })}
            </Grid>
            <Grid
              item
              md={3.5}
              xs={5}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0px 0px 0px",
              }}
            >
              {deptDrop.length > 0 && (
                <Autocomplete
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  value={Department}
                  options={deptDrop}
                  getOptionLabel={(option) => option.DepartmentName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.DepartmentId}>
                      {option.DepartmentName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Department"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(event, newValue) => {
                    setDepartment(newValue);
                    setActiveList(newValue);
                    handleDepartment(newValue);
                    assignList(newValue.DepartmentId);
                    issueList(newValue.DepartmentId);
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : null}
        <Grid container style={{ cursor: "pointer", marginTop: "5px" }}>
          <div>
            <span
              style={style5.title}
              onClick={() => {
                IssueShow();
              }}
            >
              Issue{" "}
            </span>
            <span style={{ color: "darkred" }}>*</span>
            {Issues ? (
              <span style={style5.title2} onClick={() => setShowIssues(false)}>
                / {Issues.IssueName}
              </span>
            ) : null}
          </div>
        </Grid>
        {showIssues ? (
          <Grid container>
            <Grid
              container
              item
              lg={8.5}
              xs={12}
              style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
            >
              {IssueDrop?.length > 0 ? (
                IssueDrop.map((Issues, index) => {
                  if (index < 6) {
                    return (
                      <Grid
                        item
                        md={1.7}
                        sm={8}
                        xs={12}
                        style={
                          IssueActiveList === Issues.IssueName
                            ? style5.cardsActive
                            : style5.cards
                        }
                        onClick={() => {
                          handleIssues(Issues);
                          setIssueActiveList(Issues.IssueName);
                          ETC(Issues.IssueId, Department.DepartmentId);
                        }}
                        key={index}
                      >
                        {Issues.IssueName}
                      </Grid>
                    );
                  } else {
                    IssueSelect?.push(Issues);
                  }
                })
              ) : (
                <span style={{ color: "darkred" }}>
                  No Issues Found In This Department.
                </span>
              )}
            </Grid>
            <Grid
              item
              lg={3.5}
              xs={5}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {IssueSelect.length > 0 && (
                <Autocomplete
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  value={Issues}
                  options={IssueSelect}
                  getOptionLabel={(option) => option.IssueName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.IssueId}>
                      {option.IssueName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Issue"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(event, newValue) => {
                    setIssues(newValue);
                    handleIssues(newValue);
                    ETC(newValue.IssueId);
                    setIssueActiveList(newValue);
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : null}

        <Grid
          container
          xs={12}
          style={{
            marginTop: "8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item style={{ cursor: "pointer" }}>
            <span
              style={style5.title}
              onClick={() => {
                ProductShow();
              }}
            >
              Product{" "}
            </span>
            {Product ? (
              <span style={style5.title2} onClick={() => setShowProduct(false)}>
                / {Product.ProductName}
              </span>
            ) : null}
          </Grid>
          {showProduct ? (
            <Grid container>
              <Grid
                container
                item
                lg={8.5}
                xs={12}
                style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
              >
                {productDrop?.length > 0 ? (
                  productDrop?.map((Product, index) => {
                    if (index <= 5) {
                      return (
                        <Grid
                          item
                          md={1.7}
                          sm={8}
                          xs={12}
                          style={
                            productactiveList === Product?.ProductName
                              ? style5.cardsActive
                              : style5.cards
                          }
                          onClick={() => {
                            handleProduct(Product);
                            setProductActiveList(Product?.ProductName);
                          }}
                          key={index}
                        >
                          {Product?.ProductName.substr(0, 20)}
                        </Grid>
                      );
                    } else {
                      ProductSelect?.push(Product);
                    }
                  })
                ) : (
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "darkred" }}>
                      Sorry! No Products Available For This Company.
                    </span>
                  </Grid>
                )}
                <Tooltip title="Add Product" style={{ cursor: "pointer" }}>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      if (company != null && contact != null) {
                        setProductAddOpen(true);
                      } else {
                        alert(
                          "Seems Like Company or Contact Detail Is Missing"
                        );
                      }
                    }}
                  />
                </Tooltip>
              </Grid>

              <Grid
                item
                lg={3.5}
                xs={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {ProductSelect.length > 0 && (
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="combo-box-demo"
                    value={Product}
                    options={ProductSelect}
                    getOptionLabel={(option) => option.ProductName}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.ProductId}>
                        {option.ProductName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Product"
                        variant="outlined"
                        required
                      />
                    )}
                    onChange={(event, newValue) => {
                      handleProduct(newValue);
                      setProduct(newValue);
                      setProductActiveList(newValue);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <Grid container>
          <Grid
            item
            md={3.5}
            sm={7}
            xs={12}
            style={{ margin: "15px 0px 5px 0px" }}
          >
            <span
              style={{
                fontSize: "14px",

                fontWeight: 500,
              }}
            >
              Assign To
            </span>
            <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>

            <Autocomplete
              size="small"
              id="auto-complete"
              options={assignDrop}
              value={assign}
              onChange={(event, newValue) => {
                setAssign(newValue);
              }}
              getOptionLabel={(option) => option.UserName}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select" />
              )}
            />
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <div style={{ margin: "10px 0px 10px 0px" }}>
            <span
              style={{
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {" "}
              ETC
            </span>
            <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>
          </div>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDateTimePicker
                value={DT}
                onChange={(newValue) => {
                  setDT(newValue);
                }}
                onError={(newError) => setTimeError(newError)}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                disablePast
                views={["day", "month", "year", "hours", "minutes"]}
                format="DD-MM-YYYY hh:mm A"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid
          md={7.7}
          xs={12}
          container
          item
          style={{
            margin: "10px 0px 10px 0px",
          }}
        >
          <Grid item xs={12}>
            <p
              style={{
                padding: "10px 15px",
                background: "rgba(0, 113, 188, 0.1)",
                margin: "0px",
              }}
            >
              Description
              <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>
            </p>
            <JoditEditor
              ref={editor}
              value={content}
              onBlur={(e) => setContent(e)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          md={11}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px",
            margin: "10px 0px",
            borderTop: "1px solid rgb(0,0,0,0.1)",
          }}
        >
          <Button
            size="small"
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "10px 20px",
              margin: "5px 0px 0px 0px",
            }}
            onClick={() => {
              UpdateTicket();
            }}
          >
            Save
          </Button>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </Grid>
      {ProductAddOpen && (
        <ProductAdd
          AccountsId={company.AccountsId}
          ContactId={contact.ContactId}
          ProductAddOpen={ProductAddOpen}
          setProductAddOpen={setProductAddOpen}
          setProductDrop={setProductDrop}
        />
      )}
    </>
  );
}

export default EditTicket;

const style5 = {
  cards: {
    background: "#F4F4F4",
    boxShadow:
      "1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(176, 176, 176, 0.5), inset -3px 3px 6px rgba(176, 176, 176, 0.2), inset 3px -3px 6px rgba(176, 176, 176, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.9), inset 3px 3px 8px rgba(176, 176, 176, 0.9)",
    borderRadius: "10px",
    fontSize: "14px",
    border: "hidden",
    textTransform: "none",
    fontWeight: 400,

    cursor: "pointer",
    padding: "20px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  cardsActive: {
    boxShadow:
      "1px 1px 2px rgba(255, 255, 255, 04), -1px -1px 2px rgba(176, 176, 176, 0.5), inset -3px 3px 6px rgba(176, 176, 176, 0.2), inset 3px -3px 6px rgba(176, 176, 176, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.9), inset 3px 3px 8px rgba(176, 176, 176, 0.9)",
    borderRadius: "10px",
    borderBottom: "4px solid #0071BC",
    background: "rgba(10, 124, 198, 0.1)",
    fontSize: "14px",
    textTransform: "none",
    fontWeight: 500,
    cursor: "pointer",
    padding: "20px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    fontSize: "14px",
    fontWeight: 500,
    margin: "10px 0px 10px 0px",
  },
  title2: {
    color: "#0071BC",
    fontSize: "22px",
    fontWeight: 600,
    paddingLeft: "5px",
  },
  Commentimg: {
    height: "29px",
    width: "29px",
  },
};
const formControlLabelStyle = {
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
  },
};
