import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Modal,
  Rating,
  TextField,
  Tooltip,
  p,
} from "@mui/material";
//  import Notes from '../../screens/ticketview/Notes';
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState, useRef } from "react";
import {
  CloseIcon,
  NudgeRed,
  PinImage,
  Uplodeimg,
  ViewIcon,
} from "../../Assets/Icon";
import { TicketIdIcon } from "../../Assets/Icon";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import JoditEditor from "jodit-react";
import { Close } from "@mui/icons-material";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
//Timeline
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import { Avatar, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import PredefinedShow from "./PredefinedShow";
import TicketNotes from "../View Components/TicketNotes";
import TagUsers from "../View Components/TagUsers";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeftTwoTone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ImageIcon from "@mui/icons-material/Image";

export default function AllView() {
  const location = useLocation();
  const [ticketdata, setticketdata] = useState(location.state.data);
  const [NextView, SetNextView] = useState(false);
  const [PrevView, SetPreView] = useState(false);
  const [disable, setdisable] = useState(
    global?.$userData?.logindata?.TicketingDepartmentAdmin === "Yes" ||
      global?.$userData?.logindata?.AccessAdminTicket === "Yes"
  );
  const NextTicket = () => {
    let data = location.state.Table.findIndex(
      (e) => ticketdata.TicketId == e.TicketId
    );
    if (data !== -1) {
      let value = data + 1;
      if (value < location.state.Table.length) {
        setticketdata(location?.state?.Table[value]);
        let value2 = value + 1;
        if (value2 < location.state.Table.length) {
          SetNextView(false);
          SetPreView(false);
        } else {
          SetNextView(true);
        }
      } else {
        toast.error("No Next Ticket");
      }
    } else {
      console.log("Object with id 85 not found in the array");
    }
  };
  const PreviousTicket = () => {
    let value = "";
    let data = location?.state?.Table?.findIndex(
      (e) => ticketdata.TicketId == e.TicketId
    );
    if (data !== -1) {
      let value = data - 1;
      if (value < location.state.Table.length) {
        setticketdata(location.state.Table[value]);
        let value2 = value - 1;
        if (value2 < location.state.Table.length && value != 0) {
          SetPreView(false);
          SetNextView(false);
        } else {
          SetPreView(true);
        }
      } else {
        console.log("There is no next object in the array.");
        toast.error("NO Next Ticket");
      }
    } else {
      console.log("Object with id 85 not found in the array");
    }
  };
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const valuedate = new Date();
  const Navigate = useNavigate();
  const [StatusTime, setStatusTime] = useState("");
  const [AttachmentLink, setAttachmentLink] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [rating, setRating] = useState(0);
  const [Comment, setComment] = useState("");
  const [TemplateArray, setTemplateArray] = useState([]);
  const [TemplateComment, setTemplateComment] = useState(null);
  const [Customerdate, setCustomerdate] = useState(
    dayjs(
      ticketdata?.customerduedate != ""
        ? ticketdata?.customerduedate
        : ticketdata?.ECAt
    )
  );
  //Predefined
  const [PredefinedShowOpen, setPredefinedShowOpen] = useState(false);
  const [PredefinedContent, setPredefinedContent] = useState("");

  // Notes
  const [NotesOpen, setNotesOpen] = useState(false);

  //Tag Popup
  const [Popupopen, setPopupopen] = useState(false);
  const [Edittag, SetEditTag] = useState({ Departement: "", TagedUsers: "" });
  // console.log("Edittag", Edittag.TagedUsers[0].UserName);
  //Companycount
  const [companypending, Setcompanypending] = useState({
    pending: "",
    total: "",
  });

  /* Jodit Editor*/
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [ShowDescription, setShowDescription] = useState([]);

  //Timeline
  const useStyles = makeStyles({
    timeline: {
      transform: "rotate(-90deg)",
    },
    timelineContentContainer: {
      textAlign: "left",

      margin: "0px",
    },
    timelineContent: {
      display: "inline-block",
      transform: "rotate(90deg)",
      textAlign: "center",
      background: "yellow",
      minWidth: 50,
    },
    timelineIcon: {
      transform: "rotate(-90deg)",
    },
  });
  const classes = useStyles();

  const Process = [
    { ProcessId: "2", ProcessName: "Progress" },
    { ProcessId: "3", ProcessName: "Hold" },
    { ProcessId: "4", ProcessName: "Completed" },
  ];

  const [status, setStatus] = useState(
    ticketdata.TicketStatus == 3
      ? Process[1].ProcessId
      : ticketdata.TicketStatus == 4
      ? Process[2].ProcessId
      : Process[0].ProcessId
  );

  const statusList = async () => {
    let year = `${Customerdate.$d.getFullYear()}-${
      Customerdate.$d.getMonth() + 1 < 10
        ? "0" + (Customerdate.$d.getMonth() + 1)
        : Customerdate.$d.getMonth() + 1
    }-${
      Customerdate.$d.getDate() < 10
        ? "0" + Customerdate.$d.getDate()
        : Customerdate.$d.getDate()
    } ${
      Customerdate.$d.getHours() < 10
        ? "0" + Customerdate.$d.getHours()
        : Customerdate.$d.getHours()
    }:${
      Customerdate.$d.getMinutes() < 10
        ? "0" + Customerdate.$d.getMinutes()
        : Customerdate.$d.getMinutes()
    }`;
    if (content === "") {
      toast.error("Give Description");
      return;
    } else if (status === "") {
      toast.error("Give Ticket Status");
      return;
    } else if (!TemplateComment) {
      toast.error("Predefined Replies Required");
    } else {
      let data = {
        AppCode: AppCode,
        TicketId: ticketdata?.TicketId,
        Status: status,
        UserId: userId,
        Description: content,
        Attachment: AttachmentLink,
        predefine: PredefinedContent,
        customerduedate: year,
      };
      const response = await global.$axios
        .put("/ticket/statusChange", data)
        .then(async (response) => {
          if (response.data.Status == "200") {
            toast.success("Ticket Updated Successfully");
            setTimeout(() => {
              Navigate("/app/All");
            }, 1500);
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Something Went Wrong");
        });
    }
  };

  //Get Updates
  const GetUpdate = async () => {
    const response = await global.$axios
      .put(`/ticket/updatestatus`, {
        AppCode: AppCode,
        ForId: ticketdata?.TicketId,
        TicketStatus: status,
      })
      .then(async (response) => {
        setShowDescription(response.data.Message);
      })
      .catch((e) => {
        console.error("Discussion List Issue");
      });
  };

  const [TimeList, setTimeList] = useState({
    New: { Time: null, visible: true },
    Progress: { Time: null, visible: true },
    Hold: { Time: null, visible: false },
    Completed: { Time: null, visible: true },
    Solved: { Time: null, visible: false },
  });

  //Get Time
  const GetTime = async () => {
    const response = await global.$axios
      .get(`/ticket/statustime/${AppCode}/${ticketdata?.TicketId}`)
      .then(async (response) => {
        if (response.data.Status === 200) {
          var apidata = response.data.Message;

          // Latest Progress time fetching
          const progressList = apidata.filter((x) => x.ToStatusId == 2);
          let progressTime = null;
          if (progressList.length > 0) {
            progressTime = progressList[0].StatusToTime;
          }
          // Latest Hold time fetching
          const HoldList = apidata.filter((x) => x.ToStatusId == 3);
          let HoldTime = null;
          if (HoldList.length > 0) {
            HoldTime = HoldList[0].StatusToTime;
          }
          // Latest Completed time fetching
          const CompletedList = apidata.filter((x) => x.ToStatusId == 4);
          let completedTime = null;
          if (CompletedList.length > 0) {
            completedTime = CompletedList[0].StatusToTime;
          }

          // Latest Completed time fetching
          const SolvedList = apidata.filter((x) => x.ToStatusId == 4);
          let solvedTime = null;
          if (SolvedList.length > 0) {
            solvedTime = SolvedList[0].StatusToTime;
          }

          setTimeList({
            New: { Time: ticketdata.CreatedAt, visible: true },
            Progress: { Time: progressTime, visible: true },
            Hold: { Time: HoldTime, visible: false },
            Completed: { Time: completedTime, visible: true },
            Solved: { Time: solvedTime, visible: false },
          });

          setStatusTime(response.data.Message);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("Get Time Issue", e);
      });
  };

  //Get Feedback
  const GetFeedback = async () => {
    const response = await global.$axios
      .get(`/ticket/getFeedback/${AppCode}/${ticketdata?.TicketId}`)
      .then(async (response) => {
        var res = response?.data?.Message[0];
        setRating(res?.CreatorScore);
        setComment(res?.CreatorFeedback);
      })
      .catch((e) => {
        console.error("Get FeedBack Issue", e);
      });
  };

  //company all count
  const companycount = async () => {
    const response = await global.$axios
      .get(
        `/ticket/mycustomerworkspaceCount/${AppCode}/${ticketdata.AccountId}`
      )
      .then((response) => {
        Setcompanypending((companypending) => ({
          ...companypending,
          pending:
            +response.data.msg[0].Holding +
            +response.data.msg[0].New +
            +response.data.msg[0].Progress,
          total:
            +response.data.msg[0].Holding +
            +response.data.msg[0].New +
            +response.data.msg[0].Progress +
            +response.data.msg[0].Completed +
            +response.data.msg[0].Solved,
        }));
      })
      .catch((e) => {
        console.error("companycount", e);
      });
  };

  //Get Template
  const TemplateList = async () => {
    const response = await global.$axios
      .get(`/predefined_reply/list/${AppCode}`)
      .then(async (response) => {
        setTemplateArray(response.data.Message);
      });
  };

  const makeTemplate = (templateString) => {
    return (templateData) =>
      new Function(
        `{${Object.keys(templateData).join(",")}}`,
        "return `" + templateString + "`"
      )(templateData);
  };

  let mailtemplate =
    TemplateComment && TemplateComment?.Comments?.replaceAll("{", "${");
  const tpl = makeTemplate(mailtemplate);

  //editview API
  const [Taggedview, SetTaggedview] = useState([]);
  const editview = async () => {
    const response = await global.$axios
      .get(`/ticket/view/${AppCode}/${ticketdata.TicketId}`)
      .then(async (response) => {
        SetTaggedview(response?.data?.Message?.taglistuser);
        SetEditTag((Edittag) => ({
          ...Edittag,
          Departement: response?.data?.Message?.tagdepartmentid,
          TagedUsers: response?.data?.Message?.taglistuser,
        }));
      });
  };

  useEffect(() => {
    GetUpdate();
    GetTime();
    editview();
    companycount();
    TemplateList();
    {
      ticketdata.TicketStatus === 5 && GetFeedback();
    }
    setPredefinedContent(
      TemplateComment !== null
        ? tpl({
            CompanyName: ticketdata?.contact?.account?.CompanyName || "-",
            ContactName: ticketdata?.contact?.ContactName || "-",
            Department: ticketdata?.dept?.DepartmentName || "-",
            ProductName: ticketdata?.product?.ProductName || "-",
            IssueName: ticketdata?.issue?.IssueName || "-",
            Phone: ticketdata?.contact?.MobileNumber || "-",
            Email: ticketdata?.contact?.EmailId || "-",
            TicketId: ticketdata?.TicketId || "-",
            AssignedTo: ticketdata?.assigned?.UserFirstName || "-",
            CreatedAt:
              moment(ticketdata?.CreatedAt, "YYYY-MM-DD hh:mm").format(
                "DD-MM-YY hh.mm A"
              ) || "-",
            UpdatedAt:
              moment(ticketdata?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
                "DD-MM-YY hh.mm A"
              ) || "-",
            DueDate:
              moment(ticketdata?.ECAt, "YYYY-MM-DD hh:mm").format(
                "DD-MM-YY hh.mm A"
              ) || "-",
            CompletedOn: ticketdata?.TicketClosedTime
              ? moment(ticketdata?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
                  "DD-MM-YY hh.mm A"
                )
              : "-",
            TicketSource: ticketdata?.Status || "-",
          })
        : ""
    );
    let data = location.state.Table.findIndex(
      (e) => ticketdata.TicketId == e.TicketId
    );
    if (data !== -1) {
      let value = data + 1;
      let value2 = data - 1;
      if (value < location.state.Table.length) {
        SetNextView(false);
      } else {
        SetNextView(true);
      }
      if (value2 < location.state.Table.length && data != 0) {
        SetPreView(false);
      } else {
        SetPreView(true);
      }
    }
  }, [TemplateComment, refresh, ticketdata]);

  return (
    <div>
      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item lg={3} xs={12} style={{ margin: "0px 0px" }}>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{
                  width: "20px",
                  height: "15px",
                  marginBottom: "3px",
                }}
              />
            </Tooltip>
          </span>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              fontSize: "16px",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            All /{" "}
          </span>
          <span style={styles.activehdr}>Ticket View</span>
        </Grid>

        <Grid
          container
          item
          lg={9}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            style={{
              display: "flex",
              margin: "5px 0px",
              gap: 4,
            }}
          >
            <Button
              style={{
                textTransform: "none",
                maxHeight: "26px",
                minHeight: "26px",
                padding: "12px",
                fontSize: "12px",
                borderRadius: "15px",
              }}
              variant="contained"
            >
              Internal Chat
            </Button>
            <Button
              // onClick={() => setButton("recording")}
              style={{
                textTransform: "none",
                maxHeight: "26px",
                minHeight: "26px",
                padding: "12px",
                fontSize: "12px",
                borderRadius: "15px",
              }}
              variant="contained"
            >
              Recording
            </Button>

            <Button
              style={{
                textTransform: "none",
                maxHeight: "26px",
                minHeight: "26px",
                padding: "12px",
                fontSize: "12px",
                borderRadius: "15px",
              }}
              variant="contained"
            >
              Time Log
            </Button>
            <Button
              onClick={() => setNotesOpen(true)}
              style={{
                textTransform: "none",
                maxHeight: "26px",
                minHeight: "26px",
                padding: "12px",
                fontSize: "12px",
                borderRadius: "15px",
              }}
              variant="contained"
            >
              Notes
            </Button>
            <Button
              href="AllView/CaseHistory"
              target="_blank"
              onClick={() => {
                localStorage.setItem("pending", JSON.stringify(ticketdata));
              }}
              style={{
                textTransform: "none",
                maxHeight: "26px",
                minHeight: "26px",
                padding: "12px",
                fontSize: "12px",
                borderRadius: "15px",
                color: "#FFF",
              }}
              variant="contained"
            >
              Case History
            </Button>
          </Grid>

          <Grid
            item
            style={{
              display: "flex",
              alignItems: "flex-end",
              cursor: "pointer",
              margin: "5px 0px",
            }}
          >
            <Tooltip
              title={PrevView ? "No Previous Ticket" : "Previous Ticket"}
            >
              <IconButton>
                <ArrowCircleLeftIcon
                  fontSize="large"
                  sx={{ color: "#1565c050" }}
                  onClick={() => !PrevView && PreviousTicket()}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={NextView ? "No Next Ticket" : "Next Ticket"}>
              <IconButton>
                <ArrowCircleRightIcon
                  fontSize="large"
                  sx={{ color: "#1565c050" }}
                  onClick={() => !NextView && NextTicket()}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          margin: "0px 0px 5px 0px",
          background:
            "linear-gradient(90deg, #C4C4C4 1.08%, rgba(231, 231, 231, 0.411458) 75.27%, rgba(245, 245, 245, 0.177083) 99.99%, rgba(255, 255, 255, 0) 100%)",
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          padding: "5px ",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <TicketIdIcon />
          <span
            style={{ fontSize: "14px", fontWeight: 600, paddingLeft: "10px" }}
          >
            Ticket ID
          </span>
          <span
            style={{
              paddingLeft: "15px",
              fontSize: "14px",
              fontWeight: 500,
              color: "red",
            }}
          >
            {ticketdata.TicketId}
          </span>
        </div>
        <div
          className="Ticketviewhover"
          style={{ cursor: "pointer" }}
          onClick={() => {
            localStorage.setItem("pending", JSON.stringify(ticketdata));
          }}
        >
          <Link
            to="AllCompanyTickets"
            target="_blank"
            state={{ from: "occupation" }}
            style={{ color: "black" }}
          >
            {/* <Link to={`http://localhost:3000/app/Dashboard`} target=" _blank"
            state={"occupation"}> */}

            <span
              style={{ fontSize: "14px", fontWeight: 600, paddingLeft: "10px" }}
            >
              Client Tickets :
              <span
                style={{
                  paddingLeft: "5px",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "red",
                }}
              >
                {companypending.pending} /
              </span>{" "}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  // color: "#c4c4c4"
                }}
              >
                {companypending.total}
              </span>
            </span>
          </Link>
        </div>
      </Grid>

      <Grid
        xs={12}
        container
        style={{
          margin: "0px 0px 5px 0px",
          border: "1px solid rgba(0,0,0,0.04)",
          padding: "0px 5px 5px 5px",
          borderRadius: "15px",
          boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/*New TimeLine*/}
        <Grid
          xs={10}
          container
          item
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketdata?.TicketStatus >= 1 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>New</p>{" "}
            <span>
              {moment(TimeList.New.Time, "YYYY-MM-DD hh:mm A").format(
                "DD-MM-YYYY hh:mm A"
              )}
            </span>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TimelineItem className={classes.timeline}>
              <TimelineConnector
                style={{
                  background:
                    ticketdata.TicketStatus >= 2
                      ? "#0071BC"
                      : "rgba(0,0,0,0.3)",
                }}
              />
            </TimelineItem>
          </Grid>

          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketdata.TicketStatus >= 2 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>Progress</p>{" "}
            {TimeList.Progress.Time != null && (
              <span>
                {moment(TimeList.Progress.Time, "YYYY-MM-DD hh:mm A").format(
                  "DD-MM-YYYY hh:mm A"
                )}
              </span>
            )}
          </Grid>

          {ticketdata.TicketStatus == 3 && (
            <>
              <Grid
                item
                sx={{
                  margin: "3px 0px 0px 0px",
                }}
              >
                <TimelineItem className={classes.timeline}>
                  <TimelineConnector
                    style={{
                      background:
                        ticketdata.TicketStatus >= 3
                          ? "#0071BC"
                          : "rgba(0,0,0,0.3)",
                    }}
                  />
                </TimelineItem>
              </Grid>

              <Grid
                item
                style={{
                  margin: "0px 10px 0px 10px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <TimelineSeparator>
                  {ticketdata.TicketStatus >= 3 ? (
                    <TaskAltIcon
                      fontSize="small"
                      sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                    />
                  ) : (
                    <TimelineDot />
                  )}
                </TimelineSeparator>
                <p style={{ margin: "0px" }}>Hold</p>{" "}
                {TimeList.Hold.Time != null && (
                  <span>
                    {moment(TimeList.Hold.Time, "YYYY-MM-DD hh:mm A").format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                  </span>
                )}
              </Grid>
            </>
          )}

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TimelineItem className={classes.timeline}>
              <TimelineConnector
                style={{
                  background:
                    ticketdata.TicketStatus >= 4
                      ? "#0071BC"
                      : "rgba(0,0,0,0.3)",
                }}
              />
            </TimelineItem>
          </Grid>

          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketdata.TicketStatus >= 4 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>Completed</p>{" "}
            <span>
              {ticketdata?.TicketClosedTime &&
                moment(
                  ticketdata?.TicketClosedTime,
                  "YYYY-MM-DD hh:mm A"
                ).format("DD-MM-YYYY hh:mm A")}
            </span>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TimelineItem className={classes.timeline}>
              <TimelineConnector
                style={{
                  background:
                    ticketdata.TicketStatus >= 5
                      ? "#0071BC"
                      : "rgba(0,0,0,0.3)",
                }}
              />
            </TimelineItem>
          </Grid>

          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketdata.TicketStatus >= 5 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>Solved</p>{" "}
            <span>
              {ticketdata?.TicketSolvedTime &&
                moment(
                  ticketdata?.TicketSolvedTime,
                  "YYYY-MM-DD hh:mm A"
                ).format("DD-MM-YYYY hh:mm A")}
            </span>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={1.9}
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "flex-end",
          }}
        >
          {ticketdata.nudgeTime == "" ? (
            ""
          ) : new Date(ticketdata.nudgeTime).getDate() !=
            valuedate.getDate() ? (
            ""
          ) : (
            <Tooltip title="Ticket has been Nudged">
              <NudgeRed />
            </Tooltip>
          )}
          {/* {ticketdata.TicketStatus <= 3 &&
            (Edittag?.TagedUsers?.TagedUsers?.length >= 1 ? (
              <Button
                style={{
                  textTransform: "none",
                  maxHeight: "26px",
                  minHeight: "26px",
                  padding: "12px",
                  fontSize: "12px",
                  borderRadius: "15px",
                }}
                onClick={() => setPopupopen(true)}
                variant="contained"
              >
                View Tagged
              </Button>
            ) : (
              <Button
                style={{
                  textTransform: "none",
                  maxHeight: "26px",
                  minHeight: "26px",
                  padding: "12px",
                  fontSize: "12px",
                  borderRadius: "15px",
                }}
                onClick={() => setPopupopen(true)}
                variant="contained"
              >
                Tag Users
              </Button>
            ))} */}
        </Grid>
      </Grid>

      {/*List*/}
      <Grid xs={12} container>
        <Grid
          container
          item
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          md={7}
          xs={12}
        >
          <Grid
            item
            style={{ padding: "3px", lineHeight: "27px" }}
            md={6.2}
            xs={12}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Company Name</p>
              <p style={styles.txt}>
                {ticketdata?.contact?.account?.CompanyName.slice(0, 30)}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Contact Name</p>
              <p style={styles.txt}>
                {ticketdata?.contact?.ContactName?.slice(0, 20)}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Department</p>
              <p style={styles.txt}>{ticketdata?.dept?.DepartmentName}</p>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Product</p>
              <p style={styles.txt}>{ticketdata?.product?.ProductName}</p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Issue</p>
              <p style={styles.txt}>{ticketdata?.issue?.IssueName}</p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Phone</p>
              <p style={styles.txt}>{ticketdata?.contact?.MobileNumber}</p>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Email</p>
              <p style={styles.txt}>
                {ticketdata?.contact?.EmailId
                  ? ticketdata?.contact?.EmailId
                  : "-"}
              </p>
            </Grid>

            {ticketdata.ReopenReason && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={styles.hdr}>Reopen Reason</p>
                <p style={styles.txt}>{ticketdata?.ReopenReason}</p>
              </Grid>
            )}
            {ticketdata.TicketStatus === 5 && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={styles.hdr}>Rating</p>
                <Rating name="read-only" value={rating} readOnly />
              </Grid>
            )}
          </Grid>

          {/*List RHS*/}

          <Grid
            item
            style={{ padding: "3px", lineHeight: "27px" }}
            md={5.6}
            xs={12}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Assigned By</p>
              {ticketdata?.created !== null ? (
                <p style={styles.txt}>
                  {ticketdata?.created?.UserFirstName}{" "}
                  {ticketdata?.created?.UserLastName}
                </p>
              ) : (
                <p style={styles.txt}>{ticketdata?.contact?.ContactName}</p>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Assigned To</p>
              <p style={styles.txt}>
                {ticketdata?.assigned?.UserFirstName}{" "}
                {ticketdata?.assigned?.UserLastName}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Created On</p>
              <p style={styles.txt}>
                {moment(ticketdata.CreatedAt, "YYYY-MM-DD hh:mm").format(
                  "DD-MM-YYYY hh:mm A"
                )}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Due Date</p>
              <p style={styles.txt}>
                {ticketdata.ECAt
                  ? moment(ticketdata.ECAt).format("DD-MM-YYYY hh:mm A")
                  : "-"}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Ticket Source</p>
              <p style={styles.txt}>
                {ticketdata?.ticket_source?.TicketSourceName}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>View Attachment</p>
              <p style={styles.txt}>
                {ticketdata?.AttachmentFile === ""
                  ? "-"
                  : ticketdata.AttachmentFile.split(",").map(
                      (Attachment, index) => {
                        return (
                          <Tooltip
                            style={{ paddingRight: "5px" }}
                            title={
                              <div style={{ height: "102px", width: "180px" }}>
                                <img
                                  src={Attachment}
                                  style={{
                                    width: "180px",
                                    position: "absolute",
                                    rigth: "80%",
                                  }}
                                />
                              </div>
                            }
                          >
                            <Link to={Attachment} target="blank">
                              <ViewIcon
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Link>
                          </Tooltip>
                        );
                      }
                    )}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Updated By</p>
              <p style={styles.txt}>{ticketdata?.updated?.UserFirstName}</p>
            </Grid>
            {ticketdata.TicketStatus === 5 && (
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <p style={styles.hdr}>Comments</p>
                <Grid
                  item
                  style={{
                    border: "3px solid rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    padding: "10px",
                    fontSize: "13px",
                  }}
                >
                  {Comment !== null ? Comment : "-"}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "flex-start",
              margin: "5px 0px 0px 0px",
            }}
          >
            <span
              style={{
                margin: "0px",
                padding: "0px 20px 0px 0px",
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              Comments
            </span>
            <Grid
              item
              xs={12}
              style={{
                background: "#FFF",
                padding: "5px",
                maxHeight: "30vh",
                boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
                overflowY: "auto",
                borderRadius: "15px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: ticketdata.TicketDescription,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "flex-start",
              margin: "5px 0px 0px 0px",
            }}
          >
            <span
              style={{
                margin: "0px",
                padding: "0px 20px 0px 0px",
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              {Taggedview.length != 0 ? "Tagged Users" : "Tag User"}
            </span>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {Taggedview?.map((e) => (
                <div
                  style={{
                    fontSize: "14px",
                    margin: "0px",
                    fontWeight: 700,
                    padding: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => setPopupopen(true)}
                >
                  {e?.UserName},
                </div>
              ))}
              <IconButton onClick={() => setPopupopen(true)}>
                <AddCircleOutlineIcon
                  fontSize="small"
                  sx={{ color: "#0071BC" }}
                />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "15px 0px 5px 0px",
            }}
          >
            {ticketdata.TicketStatus !== 4 && ticketdata.TicketStatus !== 5 && (
              <Grid
                item
                md={3.8}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "0px 0px 5px 0px",
                }}
              >
                <FormControl
                  sx={{
                    border: "1px #C2C2C2",
                    fontSize: "17px",
                    borderRadius: "7px",
                    m: 0,
                    fontSize: "12px",
                  }}
                  fullWidth
                  size="small"
                >
                  <Select
                    placeholder="Select Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      borderRadius: "30px",
                      backgroundColor: "#0071BC",
                      color: "#FFF",
                    }}
                    defaultValue={[Process[0].ProcessId]}
                  >
                    {Process.map((item, index) => {
                      if (
                        item.ProcessId >= ticketdata.TicketStatus &&
                        item.ProcessId &&
                        ticketdata.TicketStatus != 3
                      ) {
                        return (
                          <MenuItem value={item.ProcessId} key={index}>
                            {item.ProcessName}
                          </MenuItem>
                        );
                      }
                    })}
                    {Process.map((item, index) => {
                      if (item.ProcessId && ticketdata.TicketStatus == 3) {
                        return (
                          <MenuItem value={item.ProcessId} key={index}>
                            {item.ProcessName}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {ticketdata.TicketStatus <= 3 && (
              <Grid
                item
                md={3.8}
                xs={12}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  fullWidth
                  id="auto-complete"
                  size="small"
                  options={TemplateArray}
                  value={TemplateComment}
                  onChange={(event, newValue) => {
                    setTemplateComment(newValue);
                    setTimeout(() => {
                      {
                        newValue && setPredefinedShowOpen(true);
                      }
                    }, 200);
                  }}
                  getOptionLabel={(option) => option.Title}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.SNo}>
                      {option.Title}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Predefined Replies"
                      variant="outlined"
                      required
                    />
                  )}
                />
                {TemplateComment !== null && (
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => {
                      setPredefinedShowOpen(true);
                    }}
                  >
                    <Tooltip title="View Template">
                      <ViewIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          paddingLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                )}
              </Grid>
            )}
            {ticketdata?.TicketStatus <= 3 && (
              <Grid
                item
                md={3.8}
                xs={12}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    format="DD/MM/YYYY"
                    disabled={!disable}
                    minDate={dayjs(ticketdata?.CreatedAt)}
                    slotProps={{
                      textField: { size: "small", label: "Customer due Date" },
                    }}
                    value={Customerdate}
                    onChange={(newvalue) => setCustomerdate(newvalue)}
                  />
                </LocalizationProvider>
              </Grid>
            )}
          </Grid>

          {ticketdata?.TicketStatus <= 3 && (
            <Grid item xs={12} style={{ margin: "0px 0px 0px 0px" }}>
              <JoditEditor
                ref={editor}
                value={content}
                onBlur={(e) => setContent(e)}
                config={{
                  placeholder: "Additional Comments...",
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          style={{ padding: "10px 5px 0px 15px", maxHeight: "78vh" }}
          md={5}
          xs={12}
        >
          <Grid
            item
            xs={12}
            style={{
              border: "1px solid rgba(0,0,0,0.05",
              boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
              padding: "10px 5px",
              borderRadius: "10px",
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: "16px",
                margin: "5px 0px",
                color: "#0071BC",
              }}
            >
              Discussions
            </span>

            {ShowDescription.length !== 0 ? (
              <Grid
                sx={{
                  maxHeight: "68vh",
                  overflowY: "scroll",
                }}
                container
                item
              >
                {ShowDescription?.map((item) => {
                  if (item?.For == 7 || item?.For == 9) {
                    return (
                      <Grid
                        xs={12}
                        item
                        style={{
                          margin: "0px",
                          padding: "10px",
                        }}
                      >
                        <Grid
                          item
                          xs={11.5}
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            padding: "2px",
                          }}
                        >
                          {" "}
                          <Grid
                            container
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "2px 5px",
                              background:
                                item.For == 9
                                  ? "rgba(47,207,47,0.1)"
                                  : "rgba(0,0,0,0.1)",
                            }}
                          >
                            <Grid item>
                              <p
                                style={{
                                  margin: "0px",
                                  // color:item.For == 9 ?'#232323' : "#0071BC",
                                  fontSize: "12px",
                                }}
                              >
                                <span style={{ paddingRight: "5px" }}>
                                  {item.For == 9
                                    ? item?.contact?.FirstName.substr(0, 20)
                                    : ticketdata?.updated?.UserFirstName}
                                </span>
                                {moment(
                                  item.CreatedAt,
                                  "YYYY-MM-DD hh:mm A"
                                ).format("DD-MM-YYYY hh:mm A")}
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                style={{
                                  margin: "0px 0px 0px 0px",
                                  fontSize: "12px",
                                }}
                              >
                                {item?.InternalStatusId === 1
                                  ? "New"
                                  : item?.InternalStatusId === 2
                                  ? "Progress"
                                  : item?.InternalStatusId === 3
                                  ? "Hold"
                                  : item?.InternalStatusId === 4
                                  ? "Completed"
                                  : "Solved"}
                              </p>
                            </Grid>
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              margin: "5px 0px 0px 0px",
                              alignItems: "flex-start",
                              gap: "5px",
                              padding: "5px",
                            }}
                          >
                            <Avatar
                              alt={
                                item?.contact
                                  ? item?.contact?.FirstName
                                  : item?.create?.CreatedBy
                              }
                              src="/static/images/avatar/2.jpg"
                              style={{ marginLeft: "5px" }}
                            />
                            <div
                              style={{ fontSize: "12px" }}
                              dangerouslySetInnerHTML={{
                                __html: item?.Description,
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    );
                  } else if (item.For == 8) {
                    return (
                      <Grid
                        xs={12}
                        container
                        item
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "10px",
                          padding: "10px",
                          // borderBottom: "1px solid rgba(0,0,0,0.04)",
                        }}
                      >
                        <Grid
                          item
                          xs={11.5}
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            padding: "2px",
                          }}
                        >
                          {" "}
                          <Grid
                            container
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              background: "#0071BC1A",
                              padding: "2px 5px",
                            }}
                          >
                            <Grid item>
                              <p
                                style={{
                                  margin: "0px",
                                  fontSize: "12px",
                                  color: "#0071BC",
                                }}
                              >
                                {item?.InternalStatusId === 1
                                  ? "New"
                                  : item?.InternalStatusId === 2
                                  ? "Progress"
                                  : item?.InternalStatusId === 3
                                  ? "Hold"
                                  : item?.InternalStatusId === 4
                                  ? "Completed"
                                  : "Solved"}
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                style={{
                                  margin: "0px",
                                  color: "#0071BC",
                                  fontSize: "12px",
                                  textAlign: "end",
                                }}
                              >
                                <span style={{ paddingRight: "5px" }}>
                                  {ticketdata?.updated?.UserFirstName
                                    ? ticketdata?.updated?.UserFirstName.substr(
                                        0,
                                        20
                                      )
                                    : ""}
                                </span>
                                {moment(
                                  item.CreatedAt,
                                  "YYYY-MM-DD hh:mm A"
                                ).format("DD-MM-YYYY hh:mm A")}
                              </p>
                            </Grid>
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              margin: "5px 0px 0px 0px",
                              alignItems: "flex-start",
                              justifyContent: "flex-end",
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{ fontSize: "12px" }}
                              dangerouslySetInnerHTML={{
                                __html: item?.predefinereply,
                              }}
                            />

                            <Avatar
                              alt={ticketdata?.updated?.UserFirstName}
                              src="/static/images/avatar/2.jpg"
                              style={{ marginLeft: "5px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              margin: "0px 0px 0px 5px",
                            }}
                          >
                            <span style={{ fontSize: "12px", fontWeight: 500 }}>
                              Comments :
                            </span>
                            <div
                              style={{ fontSize: "12px" }}
                              dangerouslySetInnerHTML={{
                                __html: item?.Description,
                              }}
                            />
                          </div>
                          {item.json && (
                            <span
                              style={{ fontSize: "12px", paddingRight: "3px" }}
                            >
                              Attachments -
                            </span>
                          )}
                          {item.json &&
                            item.json.split(",").map((Attachment) => {
                              return (
                                <>
                                  <Tooltip
                                    style={{ paddingRight: "5px" }}
                                    title={
                                      <div
                                        style={{
                                          height: "102px",
                                          width: "180px",
                                        }}
                                      >
                                        <img
                                          src={Attachment}
                                          style={{
                                            width: "180px",
                                            position: "absolute",
                                            rigth: "80%",
                                          }}
                                        />
                                      </div>
                                    }
                                  >
                                    <Link to={Attachment} target="blank">
                                      <ViewIcon
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                </>
                              );
                            })}
                        </Grid>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "35%",
                }}
              >
                <ScreenSearchDesktopIcon
                  fontSize="large"
                  sx={{ width: "100px", height: "100px", color: "#c2c2c2" }}
                />
                <p
                  style={{
                    fontSize: "16",
                    fontWeight: 500,
                    color: "rgba(0,0,0,0.5)",
                    margin: "0px",
                    padding: "0px 10px",
                  }}
                >
                  No Discussions
                </p>
              </Grid>
            )}
          </Grid>
        </Grid>
        {ticketdata.TicketStatus <= 3 && (
          <Grid container style={{ margin: "10px 0px 0px 0px" }}>
            <Grid
              xs={12}
              item
              style={{
                fontSize: "14px",
                margin: "0px 0px 5px 0px",
              }}
            >
              Upload Attachment Here, If Any...
            </Grid>{" "}
            <Attachment
              setAttachmentLink={setAttachmentLink}
              AttachmentLink={AttachmentLink}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px 0px",
        }}
      >
        <Button
          disabled={ticketdata?.TicketStatus >= 4 ? true : false}
          style={{
            maxHeight: "22px",
            minHeight: "22px",
            textTransform: "none",
          }}
          variant="contained"
          onClick={() => {
            statusList();
            GetTime();
          }}
        >
          Save
        </Button>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
      {PredefinedShowOpen && (
        <PredefinedShow
          PredefinedShowOpen={PredefinedShowOpen}
          setPredefinedShowOpen={setPredefinedShowOpen}
          PredefinedContent={PredefinedContent}
          TemplateComment={TemplateComment}
        />
      )}
      {NotesOpen && (
        <TicketNotes
          NotesOpen={NotesOpen}
          setNotesOpen={setNotesOpen}
          TicketId={ticketdata.TicketId}
        />
      )}
      {Popupopen && (
        <TagUsers
          Popupopen={Popupopen}
          setPopupopen={setPopupopen}
          TicketId={ticketdata.TicketId}
          Edittag={Edittag}
          setRefresh={setRefresh}
          refresh={refresh}
          editview={editview}
        />
      )}
    </div>
  );
}

const styles = {
  SearchInput: {
    background: "transparent",
    outline: "none",
    border: "none",
    borderRadius: "20px",
    height: "15px",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "16px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
  img: {
    margin: 10,
  },
  txt: {
    fontSize: "12px",
    margin: "0px",
  },

  hdr: {
    fontSize: "14px",
    margin: "0px",
    fontWeight: 700,
  },
  txt1: {
    fontSize: "14px",
    margin: "0px",
    fontWeight: 800,
    color: "#0071BC",
  },
};

const stylebox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "white",
  boxShadow: 24,
  pt: 2,
  pb: 2,
  pl: 4,
  pr: 4,
  borderRadius: "10px",
  outline: "transparent",
  maxHeight: "60vh",
  overflowY: "auto",
  overflowX: "hidden",
};
function Attachment({
  setAttachmentLink,
  AttachmentLink,
  setRefresh,
  refresh,
}) {
  const [pin, setpin] = useState(false);
  const handleOpenpin = () => setpin(true);
  const handleClosepin = () => setpin(false);

  const [vision, setvision] = useState("");

  var d = 0;
  function getTimestampInSeconds() {
    d++;
    setvision(d);
  }

  const [file, setFile] = useState([]);

  const handleFileChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleUploadFiles = (files) => {
    const Files = [...file];
    let limitExceeded = false;
    files.some((file) => {
      if (Files.findIndex((f) => f.name === file.name) === -1) {
        Files.push(file);
        if (Files.length > 5) {
          alert(`You can add only a maximum of 5 files per Upload`);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setFile(Files);
  };

  const handleUploadClick = async () => {
    {
      file.forEach(async (files, index) => {
        var formData = new FormData();
        formData.append("file", files);

        const response = await global.$axios
          .post("ticket/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then(async (response) => {
            if (response.data.Status === 200) {
              setAttachmentLink((AttachmentLink) => [
                ...AttachmentLink,
                response.data.Message,
              ]);
              handleClosepin();
              getTimestampInSeconds();
            } else {
              toast.error("Something Went Wrong");
            }
          })

          .catch((e) => {
            console.error("Upload API Issue", e);
          });
      });
    }
    if (AttachmentLink.length == vision) {
      toast.success("File uploded Sucessfully");
    }
  };

  const removeFileAtIndex = (index) => {
    file.splice(index, 1);
    setFile(file);
    setRefresh(!refresh);
  };
  const removeAttachementIndex = (index) => {
    AttachmentLink.splice(index, 1);
    setAttachmentLink(AttachmentLink);
    setRefresh(!refresh);
  };
  return (
    <>
      {AttachmentLink.length >= 1 ? (
        <span>
          {file.map((item, index) => {
            return (
              <>
                <ImageIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: " rgb(45, 113, 188)",
                  }}
                />
                <span>{item.name}</span>
                <IconButton
                  onClick={() => {
                    removeAttachementIndex(index);
                    removeFileAtIndex(index);
                  }}
                >
                  {" "}
                  <Close />
                </IconButton>
              </>
            );
          })}
        </span>
      ) : (
        <>
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={handleOpenpin}
          >
            <PinImage style={{ width: "18px", height: "18px" }} />
            <span style={{ fontSize: "12px" }}>Insert a File...</span>
          </div>
          <Modal
            open={pin}
            onClose={handleClosepin}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={stylebox}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0px",
                  borderBottom: "1px solid rgba(0,0,0,0.04)",
                }}
              >
                <span style={{ color: "#0071BC", fontWeight: 500 }}>
                  Attachment File
                </span>
                <IconButton
                  onClick={() => {
                    handleClosepin();
                    setFile([]);
                  }}
                  sx={{ padding: "0px" }}
                >
                  <CloseIcon style={{ width: "25px", height: "25px" }} />
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Uplodeimg />
              </div>
              <Grid
                container
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <input
                    style={{ width: "70%" }}
                    type="file"
                    accept="text"
                    id="file"
                    multiple
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  /> */}
                  <div style={{ display: "none" }}>
                    <input
                      type="file"
                      accept="text"
                      id="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </div>
                  <label
                    for="file"
                    style={{
                      color: "#0071BC",
                      fontSize: "12px",
                      fontWeight: 500,
                      cursor: "pointer",
                      background: "rgb(45,113,188)",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  >
                    Select File
                  </label>
                </div>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {file.length !== 0 && (
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ fontSize: "10px" }}>
                        Tab on image to preview
                      </span>
                      {file.map((file, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "5px",
                              }}
                            >
                              <Link
                                target="blank"
                                to={URL.createObjectURL(file)}
                              >
                                <img
                                  src={URL.createObjectURL(file)}
                                  style={{ maxWidth: 200, maxHeight: 200 }}
                                  alt="Thumb"
                                />
                              </Link>
                              <button
                                onClick={() => removeFileAtIndex(index)}
                                style={{
                                  cursor: "pointer",
                                  padding: 1,
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                }}
                              >
                                {" "}
                                Remove This Image
                              </button>
                            </Grid>
                          </>
                        );
                      })}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                style={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "13px",
                }}
              >
                <span>
                  Maximum size: <span style={{ color: "darkred" }}>5MB</span>
                </span>

                <Grid
                  container
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "5px 0px",
                  }}
                ></Grid>

                <Button
                  style={{
                    maxHeight: "22px",
                    minHeight: "22px",
                    textTransform: "none",
                  }}
                  disabled={file.length >= 1 ? false : true}
                  variant="contained"
                  onClick={() => {
                    handleUploadClick();
                  }}
                >
                  Upload
                </Button>
              </Grid>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
