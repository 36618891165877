import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { CloseIcon } from "../../../../Assets/Icon";
import {
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Dialog, useMediaQuery } from "@material-ui/core";

const EditTicketPopup = ({ data, EditPopupOpen, setEditPopupOpen, active }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  console.log("data", data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setEditPopupOpen(true);
  const handleClose = () => setEditPopupOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [department, setDepartment] = useState([]);
  const [departDrop, setDepartDrop] = useState([]);
  const [ticket, setTicket] = React.useState("");
  const [ticketDrop, setTicketDrop] = useState([]);
  const [ticketName, setTicketName] = useState(data.IssueName);
  const [value, setvalue] = useState(dayjs(`0000-00-00T${data.High}`));

  const etctime =
    value !== ""
      ? value.$d.getHours() +
        ":" +
        value.$d.getMinutes() +
        ":" +
        value.$d.getSeconds()
      : "";

  let ETC = etctime.split(" ");
  let finalclose = "";
  if (ETC.length === 2 && ETC[1] == "PM") {
    const settime = ETC[0].split(":");
    if (settime[0] != 12) {
      finalclose =
        parseInt(settime[0]) +
        parseInt(12) +
        ":" +
        settime[1] +
        ":" +
        settime[2];
    } else {
      finalclose = parseInt(settime[0]) + ":" + settime[1] + ":" + settime[2];
    }
  } else {
    finalclose = ETC[0];
  }

  // validation edit

  const [err, seterr] = useState(0);
  const validation = async (val) => {
    const response = await global.$axios
      .get(`issue/validateticket/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("editticketvalidation", e);
      });
  };

  const ticketAdd = async () => {
    if (department === "" || ticket === "" || ticketName === "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data1 = {
        AppCode: AppCode,
        DepartmentId: department.DepartmentId,
        IssueCategoryId: ticket.IssueCategoryId,
        IssueName: ticketName,
        ETC: finalclose,
        UserId: userId,
      };

      const response = await axios
        .put(`issue/edit/${data.IssueId}`, data1)
        .then(async (response) => {
          if (response.data.status == 200) {
            active();
            setOpen(false);
            handleClose();
            setTicketName("");
            toast.success("Ticket Edited");
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("editticketadd", e);
        });
    }
  };
  const DepartmentDrop = async () => {
    const dropdownResponse = await global.$axios
      .get(`department/getdept/${AppCode}`)
      .then(async (dropdownResponse) => {
        setDepartDrop(dropdownResponse.data.Message);
      })
      .catch((e) => {
        console.error("editticketpopdepartementdrop", e);
      });
  };

  const TicketDrop = async (id) => {
    const dropdownResponse = await global.$axios
      .get(`issue/departmentwise/${AppCode}/${id}`)
      .then(async (dropdownResponse) => {
        console.log("dropdownResponse", dropdownResponse);
        setTicketDrop(dropdownResponse.data.Message);
      });
  };
  const edit = async () => {
    const response = await global.$axios
      .get(`issue/editview/${AppCode}/${data.IssueId}`)
      .then(async (response) => {
        console.log("response", response);
        setDepartment(response.data.Message.departments);
        TicketDrop(response.data.Message.departments.DepartmentId);
        setTicket({
          IssueCategoryId: response.data.Message.IssueCategoryId,
          IssueCategroyName: response.data.Message.IssueCategroyName,
        });
      })
      .catch((e) => {
        console.error("Ticketeditadd", e);
      });
  };

  useEffect(() => {
    DepartmentDrop();
    edit();
  }, [data]);
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"xs"}
        open={EditPopupOpen}
        onClose={handleClose}
      >
        <Box sx={{ padding: "10px 20px 20px 20px" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{ color: "#0071BC", fontSize: "16px", fontWeight: 600 }}
            >
              Edit Ticket{" "}
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0px 10px 0px",
            }}
          >
            <Grid item md={5.9} xs={12} style={{ marginBottom: "10px" }}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0px  ",
                  paddingBottom: "5px",
                }}
              >
                Department *
              </p>

              <Autocomplete
                size="small"
                fullWidth
                id="Designation"
                value={department}
                options={departDrop}
                isOptionEqualToValue={(option, value) =>
                  option.DepartmentName === value?.DepartmentName
                }
                getOptionLabel={(option) => option?.DepartmentName || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Category "
                    variant="outlined"
                  />
                )}
                onChange={(event, newValue) => {
                  setDepartment(newValue);
                  TicketDrop(newValue.DepartmentId);
                  setTicket(null);
                }}
              />
            </Grid>
            <Grid item md={5.9} xs={12}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0px  ",
                  paddingBottom: "5px",
                }}
              >
                Ticket Category *
              </p>
              <Autocomplete
                fullWidth
                size="small"
                value={ticket}
                id="Designation"
                options={ticketDrop}
                isOptionEqualToValue={(option, value) =>
                  option.IssueCategroyName === value.IssueCategroyName
                }
                getOptionLabel={(option) => option.IssueCategroyName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Category "
                    variant="outlined"
                  />
                )}
                onChange={(event, newValue) => {
                  setTicket(newValue);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0px 0px 20px 0px",
            }}
          >
            <Grid item xs={12}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0px 0px 0px 0px",
                }}
              >
                Ticket*
              </p>
              <TextField
                fullWidth
                value={ticketName}
                onChange={(e) => setTicketName(e.target.value)}
                defaultValue={data.IssueName}
                id="outlined-size-small"
                size="small"
                error={err === 1 && data.IssueName != ticketName ? true : false}
                onKeyUp={(e) => {
                  e.target.value !== "" && validation(e.target.value);
                }}
                helperText={
                  err === 1 && data.IssueName != ticketName ? (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      Already Exist
                    </span>
                  ) : (
                    <span style={{ color: "transparent" }}>""</span>
                  )
                }
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "-30px",
            }}
          >
            <p style={{ margin: "10px 0px 0px 0px", fontSize: "14px" }}>ETC*</p>
            <Grid
              xs={12}
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item md={5.9} xs={12} style={{}}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  label={"hh : mm "}
                >
                  <MobileTimePicker
                    ampm={false}
                    value={value}
                    slotProps={{ textField: { size: "small" } }}
                    onChange={(newValue) => setvalue(newValue)}
                    sx={{
                      p: 0,
                      ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "10px",
                        },
                    }}
                    views={["hours", "minutes"]}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>

          <center style={{ marginTop: "20px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                ticketAdd();
              }}
            >
              Update
            </Button>
          </center>
        </Box>
      </Dialog>
    </div>
  );
};

export default EditTicketPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  },
};
