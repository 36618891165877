import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useState } from "react";
import Save from "../../../assets/icon/Sucess.gif";
import { toast, ToastContainer } from "react-toastify";
import Sucessmodel from "../../Modal/Sucessmodel";

export default function ForgotPassword() {
  //decleration
  var localStoragedata = localStorage.getItem("loginemail");
  const handleOpen = () => setOpen(true);
  const handleClickShowPassword = () => setShowPassword(() => !showPassword);
  const handleClickconfirmPassword = () =>
    setShowConPassword(() => !showConPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const refresh = () => window.location.reload(true);
  // let Message = "";

  //usestates
  const [otp, setotp] = useState("Emailsend");
  const [Email, setEmail] = useState(localStoragedata ?? "");
  const [otpvalue, setOtpvalue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [Newpassword, setNewpassword] = useState("");
  const [ConformPass, setConformpass] = useState("");
  const [Message, setMessage] = useState("");

  //apis
  const otpsend = async () => {
    if (Email.trim() === "" || Email === null) {
      toast.error("Email is Required");
      return;
    }
    try {
      const { data } = await global.$axios.post("/auth/otpgenerate", {
        EmailId: Email,
      });
      if (data.Status === 200) {
        toast.success("Otp Send Successfully");
        setotp("verify");
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const verifyotp = async (e) => {
    const { data } = await global.$axios.post("/auth/validateOtp", {
      Email: Email,
      OTP: e,
    });
    console.log("data", data);
    if (data.Status === 200) {
      toast.success("Otp Verfied Successfully");
      setotp("confirm");
    } else {
      toast.error("Enter The Valid OTP ");
    }
  };
  const verfiypassword = async () => {
    if (Newpassword.trim() === "" || ConformPass.trim() === "") {
      toast.error("Please Fill The Password");
      return;
    } else if (
      Newpassword.trim().toLowerCase() !== ConformPass.trim().toLowerCase()
    ) {
      toast.error("Please Check the Password");
      return;
    }

    const { data } = await global.$axios.post("/auth/forgetpassword", {
      EmailId: Email,
      Password: Newpassword.trim(),
    });
    if (data.Status === 200) {
      toast.success("Password Changed Successfully");
      setMessage("Your Password has been Changed Sucessfully.....!");
      handleOpen();
      setTimeout(() => {
        refresh();
      }, 3000);
    } else {
      toast.error("SomeThing Went Wronge");
    }
  };
  return (
    <>
      <Grid
        container
        md={12}
        xs={12}
        style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
      >
        <span style={{ fontSize: "18px", fontWeight: "400", color: "white" }}>
          {" "}
          OTP Verification
        </span>
      </Grid>
      {otp === "Emailsend" && (
        <>
          <Grid
            container
            md={12}
            xs={12}
            style={{ fontSize: "14px", color: "white", marginTop: "10px" }}
          >
            We will send you an One Time Password on this verified Mail Id
          </Grid>

          <Grid container md={12} xs={12}>
            <TextField
              fullWidth
              id="email"
              value={Email}
              variant="outlined"
              type="email"
              size="small"
              style={{
                background: "rgba(255, 255, 255, 1)",
                marginTop: "10px",
                borderRadius: "10px",
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid
            container
            md={12}
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              style={{
                minHeight: "35px",
                maxHeight: "35px",
                color: "rgb(45,113,188)",
                backgroundColor: "white",
                borderRadius: "10px",
                fontWeight: "700",
                marginTop: "10px",
                textTransform: "none",
              }}
              onClick={() => otpsend()}
            >
              Get OTP
            </Button>
          </Grid>
        </>
      )}
      {otp === "verify" && (
        <>
          <Grid
            container
            md={12}
            xs={12}
            style={{
              fontSize: "14px",
              color: "white",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Enter the OTP sent to{" "}
            <Typography variant="subtitle2" sx={{ pl: 1 }}>
              {" "}
              {Email}
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MuiOtpInput
              length={4}
              value={otpvalue}
              onChange={(e) => {
                console.log("e", e);
                setOtpvalue(e);
                if (e.length === 4) {
                  verifyotp(e);
                }
              }}
              TextFieldsProps={{
                sx: {
                  color: "black",
                  width: "55px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  borderColor: "@232323",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              }}
            />
          </Grid>
          {/* <Grid
            container
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button
              variant="contained"
              style={{
                minHeight: "35px",
                maxHeight: "35px",
                color: "rgb(45,113,188)",
                backgroundColor: "white",
                borderRadius: "10px",
                fontWeight: "700",
                textTransform: "none",
              }}
              onClick={() => setotp("confirm")}
            >
              Verify
            </Button>
          </Grid> */}
        </>
      )}
      {otp === "confirm" && (
        <>
          <Grid container md={12} xs={12} style={{ marginTop: "10px" }}>
            <span
              style={{
                fontSize: "14px",
                color: "white",
                fontWeight: "600",
                margin: "2px",
                textTransform: "none",
              }}
            >
              New Password
            </span>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                background: "rgba(255, 255, 255, 1)",
                borderRadius: "10px",
              }}
            >
              <OutlinedInput
                fullWidth
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                value={Newpassword}
                onChange={(x) => {
                  setNewpassword(x.target.value);
                }}
              />
            </FormControl>

            <span
              style={{
                fontSize: "14px",
                color: "white",
                fontWeight: "600",
                margin: "2px",
                textTransform: "none",
              }}
            >
              Confirm Password
            </span>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                background: "rgba(255, 255, 255, 1)",
                borderRadius: "10px",
              }}
            >
              <OutlinedInput
                fullWidth
                // placeholder='confirm password'
                type={showConPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickconfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                value={ConformPass}
                onChange={(e) => setConformpass(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid
            container
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button
              variant="contained"
              style={{
                minHeight: "35px",
                maxHeight: "35px",
                color: "rgb(45,113,188)",
                backgroundColor: "white",
                borderRadius: "10px",
                fontWeight: "700",
                textTransform: "none",
              }}
              onClick={() => verfiypassword()}
            >
              Confirm{" "}
            </Button>
          </Grid>

          {open && (
            <Sucessmodel
              open={open}
              setOpen={setOpen}
              message={Message}
              setMessage={setMessage}
            />
          )}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};
