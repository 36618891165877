import { Dialog, IconButton, useMediaQuery } from "@material-ui/core";
import { Autocomplete, Button, Grid, TextField, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CloseIcon } from "../../Assets/Icon";

function Addmorcontact({
  ContactAddOpen,
  setContactAddOpen,
  Refresh,
  setRefresh,
}) {
  const Navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //UseStates
  const [Company, setCompany] = useState(null);
  const [CompanyId, setCompanyId] = useState("");
  const [CompanyDrop, setCompanyDrop] = useState([]);
  const handleClose = () => setContactAddOpen(false);
  const [BranchDrop, setBranchDrop] = useState([]);
  const [Branch, setBranch] = useState(null);
  const [Name, setName] = useState("");
  const [MailId, setMailId] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [DesignationDrop, setDesignationDrop] = useState([]);
  const [Designation, setDesignation] = useState(null);
  const [DepartmentDrop, setDepartmentDrop] = useState([]);
  const [Department, setDepartment] = useState(null);
  const [ContactOwner, setContactOwner] = useState(null);
  const [ContactOwnerDrop, setContactOwnerDrop] = useState([]);
  const [Comment, setComment] = useState("");
  const [MCount, setMCount] = useState(false);

  //APIs
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const CompanyList = async () => {
    const response = await global.$axios.get(
      `/company/getcompanylist/${AppCode}`
    );
    // console.log("CompanyList", response.data);
    setCompanyDrop(response.data.message);
  };

  const BranchList = async (AccountId) => {
    if (!AccountId) {
      setBranchDrop([]);
      setBranch(null);
    } else {
      const response = await global.$axios.get(
        `/company/branchlist/${AppCode}/${AccountId}`
      );
      setBranchDrop([
        { AccountsBranchId: "Ho", BranchName: "HO" },
        ...response.data.message,
      ]);
      setBranch({ AccountsBranchId: "Ho", BranchName: "HO" });
    }
  };

  const DesignationList = async () => {
    const response = await global.$axios.get(
      `/customerdesignation/cdesignation/${AppCode}`
    );
    setDesignationDrop(response.data.Message);
  };

  const DepartmentList = async () => {
    const response = await global.$axios.get(
      `/customerdepartment/cdepartment/${AppCode}`
    );
    setDepartmentDrop(response.data.Message);
  };

  const contactOwnerList = async () => {
    const response = await global.$axios
      .get(`/company/accountuserlist/${AppCode}`)
      .then(async (response) => {
        setContactOwnerDrop(response.data.message);
      })
      .catch((e) => {
        console.error("Contact Owner List Issue", e);
      });
  };

  //Mobile digit Validate
  const MobileVali = (e) => {
    if (MobileNumber.length !== 10) {
      setMCount(true);
    } else {
      setMCount(false);
    }
  };
  // Mobile Exist Validate
  const [MobileErr, setMobileErr] = useState(0);
  const MobileVal = async () => {
    const response = await global.$axios
      .get(`/company/contact/validatemobile/${AppCode}/${MobileNumber}`)
      .then(async (response) => {
        setMobileErr(response.data.Message);
      })
      .catch((e) => {
        console.error("Contact Mobile Exist Issue", e);
      });
  };

  // Mail Exist Validate
  const [MailError, setMailError] = useState(0);
  const EMailValid = async () => {
    const response = await global.$axios
      .get(`/company/contact/validatemail/${AppCode}/${MailId}`)
      .then(async (response) => {
        setMailError(response.data.Message);
      })
      .catch((e) => {
        console.error("Contact Mail Exist Issue", e);
      });
  };

  //Mail Format Validate
  const [NoValidEmail, setNoValidEmail] = useState(false);
  const MailValidate = (val) => {
    if (val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setNoValidEmail(false);
    } else {
      setNoValidEmail(true);
    }
  };

  // Add Contact
  const CreateContact = async () => {
    if (
      CompanyId === "" ||
      Name === "" ||
      MailId === "" ||
      ContactOwner == null ||
      MobileNumber === ""
    ) {
      toast.error("You Missed Required Fields");
      return;
    } else if (MobileNumber.length !== 10) {
      toast.error("Number Must Be 10 Digits");
      return;
    } else if (NoValidEmail) {
      toast.error("Please Provide Valid Mail");
      return;
    } else {
      let data = {
        AppCode: AppCode,
        Department: Department?.CDepartmentId,
        AccountsId: CompanyId,
        BranchId: Branch?.BranchName,
        FirstName: Name,
        MobileNumber: MobileNumber,
        EmailId: MailId,
        Designation: Designation?.CDesignationId,
        Description: Comment,
        UserId: userId,
        ContactOwner: ContactOwner?.UserId,
      };
      const response = await global.$axios.post("/company/contact/add", data);
      if (response.data.status === 200) {
        toast.success("Contact Added Successfully");
        setDepartment(null);
        setCompany(null);
        setBranch(null);
        setName("");
        setMobileNumber("");
        setMailId("");
        setDesignation(null);
        setComment("");
        setContactOwner(null);
        setTimeout(() => {
          handleClose();
        }, 1500);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    CompanyList();
    contactOwnerList();
    DesignationList();
    DepartmentList();
  }, []);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={ContactAddOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ padding: "20px" }}>
          <Grid
            container
            spacing={0}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  margin: "0px",
                  color: "#0071BC",
                  cursor: "pointer",
                }}
                onClick={() => {
                  Navigate(-1);
                }}
              >
                Companies /
              </span>{" "}
              <span
                style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}
              >
                {" "}
                Add Contact
              </span>
            </div>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
            <Grid
              container
              xs={12}
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item md={5.8} xs={12} style={{ marginTop: "5px" }}>
                <p style={style3.txttitle}>Company Name *</p>

                <Autocomplete
                  size="small"
                  id="auto-complete"
                  value={Company}
                  options={CompanyDrop}
                  getOptionLabel={(option) => option.CompanyName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.AccountsId}>
                      {option.CompanyName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      BranchList(null);
                    } else {
                      BranchList(newValue.AccountsId);
                    }
                    setCompany(newValue);
                    setCompanyId(newValue.AccountsId);
                    setBranch(BranchDrop[0]);
                  }}
                />
              </Grid>

              <Grid item md={5.8} xs={12} style={{ marginTop: "5px" }}>
                <div style={style3.txttitle}>Branch</div>

                <Autocomplete
                  size="small"
                  id="auto-complete"
                  value={Branch}
                  options={BranchDrop}
                  getOptionLabel={(option) => option.BranchName}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.AccountsBranchId}
                    >
                      {option.BranchName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setBranch(newValue);
                  }}
                />
              </Grid>
              {/* <Grid item md={3.5} xs={12}></Grid> */}
            </Grid>

            <Grid
              container
              xs={12}
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Grid item md={3.5} xs={12} style={{ marginTop: "5px" }}>
                <p style={style3.txttitle}>Name *</p>
                <TextField
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  placeholder="Enter The Name"
                  size="small"
                />
              </Grid>

              <Grid item md={3.5} xs={12} style={{ marginTop: "5px" }}>
                <p style={style3.txttitle}>Mail ID *</p>
                <TextField
                  value={MailId}
                  onChange={(e) => {
                    setMailId(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    e.target.value != "" && EMailValid(e.target.value);
                    MailValidate(e.target.value);
                  }}
                  helperText={
                    MailError === 1 ? (
                      <span style={{ color: "darkred" }}>Already Exist</span>
                    ) : NoValidEmail ? (
                      <span style={{ color: "darkred" }}>Not Valid Mail</span>
                    ) : (
                      <span style={{ color: "transparent" }}>
                        Already Exist
                      </span>
                    )
                  }
                  fullWidth
                  placeholder="Enter Your Mail ID"
                  size="small"
                  required
                  id="outlined-required"
                  type="email"
                />
              </Grid>

              <Grid item md={3.5} xs={12} style={{ marginTop: "5px" }}>
                <p style={style3.txttitle}>Mobile Number *</p>
                <TextField
                  value={MobileNumber}
                  type="number"
                  inputProps={{
                    sx: {
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                    },
                  }}
                  onChange={(e) =>
                    setMobileNumber(
                      Math.max(0, parseInt(e.target.value.trim()))
                        .toString()
                        .slice(0, 10)
                    )
                  }
                  onKeyUp={(e) => {
                    e.target.value !== "" && MobileVali(e.target.value);
                    MobileVal(e.target.value);
                  }}
                  helperText={
                    MCount === true ? (
                      <span style={{ color: "darkred" }}>
                        10 Digits Required
                      </span>
                    ) : MobileErr === 1 ? (
                      <span style={{ color: "darkred" }}>Already Exist</span>
                    ) : (
                      <span style={{ color: "transparent" }}>
                        Already Exist
                      </span>
                    )
                  }
                  fullWidth
                  placeholder="Enter Your  Mobile Number"
                  size="small"
                  required
                  id="outlined-required"
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item md={3.5} xs={12} style={{ marginTop: "5px" }}>
                <p style={style3.txttitle}>Designation</p>
                <Autocomplete
                  size="small"
                  id="auto-complete"
                  value={Designation}
                  options={DesignationDrop}
                  getOptionLabel={(option) => option.CDesignationName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.CDesignationId}>
                      {option.CDesignationName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setDesignation(newValue);
                    // setBranchName(newValue.Area);
                  }}
                />
              </Grid>

              <Grid item md={3.5} xs={12} style={{ marginTop: "5px" }}>
                <p style={style3.txttitle}>Department</p>

                <Autocomplete
                  size="small"
                  id="auto-complete"
                  value={Department}
                  options={DepartmentDrop}
                  getOptionLabel={(option) => option.CDepartmentName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.CDepartmentId}>
                      {option.CDepartmentName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setDepartment(newValue);
                    // setBranchName(newValue.Area);
                  }}
                />
              </Grid>

              <Grid item md={3.5} xs={12} style={{ marginTop: "5px" }}>
                <p style={style3.txttitle}>Contact Owner *</p>

                <Autocomplete
                  size="small"
                  id="auto-complete"
                  value={ContactOwner}
                  options={ContactOwnerDrop?.sort(
                    (a, b) =>
                      -b.ContactOwner.localeCompare(a.ContactOwner, "en", {
                        sensitivity: "base",
                      })
                  )}
                  getOptionLabel={(option) => option.ContactOwner}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.UserId}>
                      {option.ContactOwner}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setContactOwner(newValue);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <Grid item xs={12} style={{ marginTop: "5px" }}>
                <p style={style3.txttitle}>Comment</p>
                <Box sx={{}}>
                  <textarea
                    value={Comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Enter the Comment"
                    style={{
                      width: "100%",
                      border: "1px solid gray",
                      background: "#f5f5f5",
                      borderRadius: "10px",
                      padding: "10px",
                      fontSize: "18px",
                      fontWeight: "200",
                      height: "80px",
                      outline: "none",
                    }}
                  ></textarea>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "15px 0px 10px 0px",
                borderTop: "1px solid rgb(0,0,0,0.2)",
                padding: "15px",
              }}
            >
              <Button
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                disabled={NoValidEmail || MobileErr == 1 || MCount}
                size="small"
                variant="contained"
                onClick={() => {
                  CreateContact();
                  setRefresh(!Refresh);
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
}

export default Addmorcontact;
const style3 = {
  font1: {
    font: "roboto",
    fontWeight: "700",
    color: "rgba(17, 64, 232, 1)",
    lineHeight: "20px",
    fontSize: "35px",
  },
  font2: {
    font: "roboto",
    fontWeight: "700",
    color: "rgba(17, 64, 232, 1)",
    lineHeight: "20px",
    fontSize: "24px",
  },
  txttitle: {
    margin: "0px 0px 5px 0px",
    fontSize: "14px",
  },
};

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
    background: "#fff",
  },
};
