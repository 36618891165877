import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Save from "../../assets/icon/Sucess.gif";

export default function Sucessmodel({ open, setOpen, message, setMessage }) {
  const handleClose = () => setOpen(false);
  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
      setMessage("");
    }, 3000);
  }, []);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "200px", height: "180px" }}
              src={Save}
              alt=""
            />
            <div>{message}</div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};
