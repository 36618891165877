import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
import RoleTable from "./ActiveTables/RoleTable";
import MenuRolePopup from "./PopupScreens/MenuRolePopup";
import { useNavigate } from "react-router-dom";
import RoleTableInactive from "./InActiveTables/RoleTableInactive";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const MenuRole = () => {
  const [sub, setSub] = useState("Active");
  const Navigate = useNavigate();
  const [RoleOpen, setRoleOpen] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{
                  width: "20px",
                  height: "15px",
                  marginBottom: "3px",
                }}
              />
            </Tooltip>
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => Navigate(-1)}
          >
            Masters /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            {" "}
            Role
          </span>
        </div>
        <Button
          style={{
            maxHeight: "22px",
            minHeight: "22px",
            textTransform: "none",
            padding: "12px",
          }}
          size="small"
          variant="contained"
          onClick={() => setRoleOpen(true)}
        >
          + Add Role
        </Button>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          item
          md={9}
          sm={9}
          xs={12}
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            variant={sub === "Active" ? "contained" : "text"}
            onClick={() => setSub("Active")}
          >
            Active
          </Button>
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            variant={sub === "Inactive" ? "contained" : "text"}
            onClick={() => setSub("Inactive")}
          >
            Inactive
          </Button>
        </Grid>

        {(sub === "Active" && (
          <RoleTable Refresh={Refresh} setRefresh={setRefresh} />
        )) ||
          (sub === "Inactive" && <RoleTableInactive />)}
      </Grid>

      {RoleOpen && (
        <MenuRolePopup
          Refresh={Refresh}
          setRefresh={setRefresh}
          RoleOpen={RoleOpen}
          setRoleOpen={setRoleOpen}
        />
      )}
    </div>
  );
};

export default MenuRole;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};
