import axios from "axios";

// axios start
// axios.defaults.baseURL = "http://172.16.5.5:6032";
// axios.defaults.baseURL = "http://172.16.5.27:4002";
// axios.defaults.baseURL = "http://172.16.5.11:7014";
// axios.defaults.baseURL = "http://172.16.5.213:4003";
//
//staging
// axios.defaults.baseURL = "https://bkapistaging.treeone.one/";
//live
axios.defaults.baseURL = "https://bkticketapi.treeone.one/";
//
global.$axios = axios;
global.$test = "axios";
// axios end
